import "./Spinner.css"

type SpinnerProps = {
  text?: string;
  showText?: boolean;
}

export default function Spinner({ showText, text }: SpinnerProps) {
  return (
    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
      <div className="spinner" />
      {showText && <div style={{marginLeft: "3px"}}>{text}</div>}
    </div>
  );
};
