
export const TURF_TYPE_TEAM_OF_TEAMS: number = 1;
const TURF_TYPE_HOUSE: number = 2;
const TURF_TYPE_PRODUCT_AREA: number = 3;
const TURF_TYPE_BUSINESS_UNIT: number = 4;

export const TURF_TYPES = [
  { id: TURF_TYPE_TEAM_OF_TEAMS, name: "Team of Teams" },
  { id: TURF_TYPE_HOUSE, name: "House" },
  { id: TURF_TYPE_PRODUCT_AREA, name: "Product Area" },
  { id: TURF_TYPE_BUSINESS_UNIT, name: "Business Unit" },
];

export const turf_type_attributes = {
  [TURF_TYPE_TEAM_OF_TEAMS]: {
    color: "#690492",
    icon: "team_of_teams.svg",
  },
  [TURF_TYPE_HOUSE]: {
    color: "#0657C2",
    icon: "house.svg",
  },
  [TURF_TYPE_PRODUCT_AREA]: {
    color: "#4B9204",
    icon: "product_area.svg",
  },
  [TURF_TYPE_BUSINESS_UNIT]: {
    color: "#921F04",
    icon: "business_unit.svg",
  },
};

function Turf() {
  return (
    <></>
  );
}

export { Turf };
