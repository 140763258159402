import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useCrewApi from "../../../apiHooks/useCrewApi";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CREW_TYPES, INVESTMENT_HORIZONS } from "../../entities/Crew";
import { LIFECYCLE_STAGES } from "../../entities/Base";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import Spinner from "../../utils/Spinner";

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function CrewDetails() {

  useIsAuthenticated();

  const navigate = useNavigate();

  const { baseId, crewId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  const removeSuccessCB = () => {
    navigate(`/base/${baseId}`);
  }

  const { crewQuery, editCrew, removeCrew } = useCrewApi({ baseId: baseId ?? "", user, crewId, removeSuccessCB });

  const multiSelectStyle = {
    height: "110px",
    padding: "var(--space-xsmall) var(--space-xlarge) var(--space-xsmall) var(--space-small)",
    fontFamily: "var(--body-font)",
    fontSize: "var(--font-size-large)",
    width: "100%",
  }
  return (
    // <div className="grid form-example">
    <div className="form-example" style={{ marginBottom: "3em" }}>
      <div style={{ marginBottom: "1em" }}>
        <Link className="link link-primary" to="/bases/">Home</Link> / <Link className="link link-primary" to={`/base/${baseId}`}>Base</Link> / {crewQuery.isSuccess ? crewQuery.data.name : "Crew"}
      </div>
      {/* <form className="cs2 ce9 form-example--main-content"> */}
      <div style={{marginTop: "1em", marginBottom: "1em"}}>
        {crewQuery.isFetching && <Spinner text="Loading..." showText={true} />}
      </div>
      {!crewQuery.isFetching && crewQuery.data && <Formik
        enableReinitialize={true}
        initialValues={{ ...crewQuery.data }}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          values.lifecycleStage = Number(values.lifecycleStage);
          values.type = Number(values.type);
          if (values.investmentHorizons && values.investmentHorizons.length) {
            for (let i = 0; i < values.investmentHorizons.length; i++) {
              values.investmentHorizons[i] = Number(values.investmentHorizons[i]);
            }
          }
          console.log("value, sss", values);
          editCrew(values);
          actions.setSubmitting(false);
          // actions.resetForm();
        }}
      >
        <Form id="crewEditForm" className="grid">
          <div className="cs1 ce11 form-group">
            <label htmlFor="name">Name</label>
            <Field name="name" placeholder="Crew name" className="input" />
            <ErrorMessage className="text-red-700" name="name" component="div" />
          </div>
          <div className="cs1 ce12 form-group">
            <label htmlFor="type">Type</label>
            <Field name="type" as="select" className="select">
              {CREW_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
            </Field>
            <ErrorMessage className="text-red-700" name="type" component="div" />
          </div>
          <div className="cs1 ce12 form-group">
            <label htmlFor="lifecycleStage">Lifecycle Stage</label>
            <Field name="lifecycleStage" as="select" className="select">
              <option value={0}>None</option>
              {LIFECYCLE_STAGES.map((stage) => <option key={stage.id} value={stage.id}>{stage.name}</option>)}
            </Field>
            <ErrorMessage className="text-red-700" name="lifecycleStage" component="div" />
          </div>
          <div className="cs1 ce12 form-group">
            <label htmlFor="investmentHorizons">Investment Horizons</label>
            <Field name="investmentHorizons" as="select" multiple className="" style={multiSelectStyle}>
              {INVESTMENT_HORIZONS.map((horizon) => <option key={horizon.id} value={horizon.id}>{horizon.name}</option>)}
            </Field>
            <ErrorMessage className="text-red-700" name="investmentHorizons" component="div" />
          </div>
          <button type="submit" className="cs1 ce12 button button-primary">
            Save
          </button>
        </Form>

      </Formik>}
      <div className="grid" style={{ marginTop: "0.5rem" }}>
        <button type="button" onClick={() => { removeCrew(crewId ?? "") }} className="cs1 ce12 button button-danger">
          Delete Crew
        </button>
      </div>
    </div>
  );
}
