import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { Outlet, Link } from "react-router-dom";

export default function Layout() {
  const { user, logOut } = useContext(AuthContext) as AuthContextType;

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div style={{ height: "100%"}}>
      <nav className="">
      </nav>
      <main style={{ height: "100%"}}>
        <div style={{ height: "100%", overflow: "auto", padding: "1em", marginBottom: "3em" }}>
          <Outlet />
          <div>&nbsp;</div>
        </div>
        <div className="grid" style={{
          marginTop: "1rem",
          position: "sticky",
          "bottom": 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "gray",
          padding: "0.2em",
          paddingRight: "1.2em",
          paddingLeft: "1.2em",
        }}>
          <div style={{"color": "white"}}><p style={{lineHeight: "0px"}} className="p-small">Developed by unFIX</p></div>
          <div style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}>
            <a target="_blank" rel="noreferrer" href="https://plotter.unfix.com"><span style={{ filter: "invert(100%)", marginLeft: "0.5em", cursor: "pointer" }} className="icon icon-public"></span></a>
            <Link to="/help"><span style={{ filter: "invert(100%)", marginLeft: "0.5em", cursor: "pointer" }} className="icon icon-help-question"></span></Link>
            {user && <a href="#" onClick={() => {window.location.reload()}}><span style={{ filter: "invert(100%)", marginLeft: "0.5em", cursor: "pointer" }} className="icon icon-refresh"></span></a>}
            {user && <a href="#" onClick={handleLogout}><svg style={{ cursor: "pointer", marginLeft: "0.5em" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1188 1000"><path fill="white" d="m912 236l276 266l-276 264V589H499V413h413V236zM746 748l106 107q-156 146-338 146q-217 0-365.5-143.5T0 499q0-135 68-250T251.5 67.5T502 1q184 0 349 148L746 255Q632 151 503 151q-149 0-251.5 104T149 509q0 140 105.5 241T502 851q131 0 244-103z" /></svg></a>}
          </div>
        </div>
      </main>
    </div>
  );
}
