import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useValueStreamApi from "../../../apiHooks/useValueStreamApi";
import { DBValueStreamType } from "../../../common/types";
import { drawValueStream } from "./../lib/draw";
import { valueStream_type_attributes } from "../../entities/ValueStream";
import { Shape } from "@mirohq/websdk-types";
import { SyncedBaseIdType } from "./SyncLogic";
import { lifecycle_stage_attributes } from "../../entities/Base";

type ValueStreamMiroData = {
  shapeId: string;
}

type ValueStreamAppData = {
  valueStream: DBValueStreamType;
  appData: ValueStreamMiroData;
}

type SyncedValueStreamsProps = {
  syncedBase: SyncedBaseIdType;
}

export default function SyncedValueStreams({ syncedBase }: SyncedValueStreamsProps) {
  const { user } = useContext(AuthContext) as AuthContextType;
  const { valueStreamsQuery } = useValueStreamApi({ user, baseId: syncedBase.id });

  const copyValueStreamAppData = (valueStream: ValueStreamAppData) => {
    return {
      valueStream: {
        ...valueStream.valueStream,
        measurements: { ...valueStream.valueStream.measurements }
      },
      appData: { ...valueStream.appData },
    };
  };

  const syncValueStreams = async (valueStreams: DBValueStreamType[]) => {

    // read last saved state from board metadata

    let valueStreamsAppData: ValueStreamAppData[] = await window.miro.board.getAppData(`unfix_valueStreams_${syncedBase.id}`);

    if (!valueStreamsAppData) {
      valueStreamsAppData = [];
    }

    let foundValueStreams: ValueStreamAppData[] = [];
    let toCreate: DBValueStreamType[] = [];
    let createdValueStreams: ValueStreamAppData[] = [];
    let deletedFromBoard: ValueStreamAppData[] = [];
    let processedIds: string[] = [];

    // iterate over valueStreams from the db to see if we can find them in the metadata
    // and on the board.

    for (let valueStream of valueStreams) {
      let found: boolean = false;
      for (let appValueStream1 of valueStreamsAppData) {
        if (valueStream.id === appValueStream1.valueStream.id) {
          found = true;
          processedIds.push(appValueStream1.valueStream.id);

          try {
            const shape = await window.miro.board.getById(appValueStream1.appData.shapeId);
            foundValueStreams.push(copyValueStreamAppData(appValueStream1));
          } catch (e) {
            console.log("ValueStream was deleted from the board", appValueStream1.valueStream.name);
            deletedFromBoard.push(copyValueStreamAppData(appValueStream1));
          }
        }
      }

      // if the valueStream wasn't found in the metadata, then we will have to create it on the board
      if (!found) {
        toCreate.push({
          ...valueStream,
          measurements: { ...valueStream.measurements }
        })
      }
    }

    // if a valueStream was found in the metadata, but not on the board, then we will redraw it
    for (let i = 0; i < deletedFromBoard.length; i++) {
      let miroData = await drawValueStream(deletedFromBoard[i].valueStream, syncedBase.left, syncedBase.top);
      deletedFromBoard[i].appData = {
        shapeId: miroData.shapeId,
      };
    }

    // creating new valueStreams
    for (let i = 0; i < toCreate.length; i++) {
      let miroData = await drawValueStream(toCreate[i], syncedBase.left, syncedBase.top);

      createdValueStreams.push(copyValueStreamAppData({
        valueStream: toCreate[i],
        appData: miroData,
      }))
    }

    // if deleting a valueStream wasn't synced to a board yet, it's te to do it
    // we check all the valueStreams in the metadata, to see if they have a corresponding db entry
    // and if not, we delete them from the board
    for (let i = 0; i < valueStreamsAppData.length; i++) {
      let found: boolean = false;
      for (let processedId of processedIds) {
        if (valueStreamsAppData[i].valueStream.id === processedId) {
          found = true;
          break;
        }
      }

      if (!found) {
        try {
          console.log("deleting: ", valueStreamsAppData[i].valueStream.name);

          // @ts-ignore
          const shape: Shape = await window.miro.board.getById(valueStreamsAppData[i].appData.shapeId);
          const metaData = await shape.getMetadata("unfix");

          // @ts-ignore
          for (let componentId of metaData.toSelect) {
            try {
              const component = await window.miro.board.getById(componentId);
              await window.miro.board.remove(component);
            } catch (e) {
              continue;
            }
          }
        } catch (e) {
          console.log("Could not delete");
        }

      }
    }

    // save the synced state
    const newValueStreamsAppData: ValueStreamAppData[] = [...foundValueStreams, ...deletedFromBoard, ...createdValueStreams];

    await window.miro.board.setAppData(`unfix_valueStreams_${syncedBase.id}`, newValueStreamsAppData);

    return newValueStreamsAppData;
  }

  const syncValueStreamNameAndType = async (valueStreams: DBValueStreamType[], valueStreamsAppData: ValueStreamAppData[]) => {
    let newValueStreamsAppData: ValueStreamAppData[] = [];
    for (let valueStream of valueStreams) {
      for (let appValueStream of valueStreamsAppData) {
        if (valueStream.id !== appValueStream.valueStream.id) {
          continue;
        }

        let textId = "";
        let imageId = "";
        let shape = null;
        let metaData;
        // let ty

        try {
          shape = await window.miro.board.getById(appValueStream.appData.shapeId);

          // @ts-ignore
          metaData = await shape.getMetadata("unfix");
          textId = metaData.textId;
          imageId = metaData.imageId;

        } catch (e) {
          console.log("Couldn't find valueStream shape to sync");
        }

        // if the type of the valueStream was changed in the meantime
        // we change the color and the type image

        if (shape && valueStream.type != appValueStream.valueStream.type) {
          // @ts-ignore
          shape.style = {
            // @ts-ignore
            ...shape.style,
            // borderColor: valueStream_type_attributes[valueStream.type]["color"],
            fillColor: valueStream_type_attributes[valueStream.type]["color"],
          }

          await shape.sync();

          // image url can't be updated so we have to delete the image
          // and create the new one for the new type
          if (imageId) {
            try {
              let imageItem = await window.miro.board.getById(imageId);
              await window.miro.board.remove(imageItem);

              const iconFile = valueStream_type_attributes[valueStream.type]["icon"];
              const newImage = await window.miro.board.createImage({
                url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/value_stream/${iconFile}`,
                //@ts-ignore
                x: imageItem.x,
                //@ts-ignore
                y: imageItem.y,
                //@ts-ignore
                width: imageItem.width,
              });

              metaData.imageId = newImage.id;
              let newToSelect = metaData.toSelect.map((toSelect: string) => toSelect == imageItem.id ? newImage.id : toSelect);
              metaData.toSelect = [...newToSelect];
              // @ts-ignore
              await shape.setMetadata("unfix", metaData);

            } catch (e) {
              console.log("Couldn't find image shape");
            }
          }
        }

        // check if valueStream name is the same on in the db and on the board
        if (textId) {
          try {
            const textItem = await window.miro.board.getById(textId);
            if (textItem && "content" in textItem && textItem.content !== valueStream.name) {
              textItem.content = valueStream.name;
              await textItem.sync();
            }
          } catch (e) {
            console.log("Couldn't find valueStream shape");
          }
        }

        // check lifecycle stage difference and redraw if needed
        if (valueStream.lifecycleStage !== appValueStream.valueStream.lifecycleStage) {
          if (metaData.lifecycleStageShapeId) {
            try {
              let lifecycleStageShape = await window.miro.board.getById(metaData.lifecycleStageShapeId);
              await window.miro.board.remove(lifecycleStageShape);

              metaData.lifecycleStageShapeId = "";
              let newToSelect = metaData.toSelect.filter((toSelect: string) => toSelect != lifecycleStageShape.id);
              metaData.toSelect = [...newToSelect];
            } catch (e) {
              console.log("Couldn't find lifecycle stage shape");
            }
          }

          if (shape && valueStream.lifecycleStage) {
            let stageShape;
            stageShape = await window.miro.board.createShape({
              shape: "circle",
              content: `${valueStream.lifecycleStage}`,
              // @ts-ignore
              x: shape.x - (shape.width / 2) + 40,
              // @ts-ignore
              y: shape.y,
              width: 40,
              height: 40,
              style: {
                color: "#FFFFFF",
                borderColor: "#1a1a1a",
                fillColor: lifecycle_stage_attributes[valueStream.lifecycleStage]["color"]
              }
            })


            metaData.lifecycleStageShapeId = stageShape.id;
            metaData.toSelect.push(stageShape.id);
          }

          // @ts-ignore
          await shape.setMetadata("unfix", metaData);
        }


        // save new modifications to the board
        newValueStreamsAppData.push({
          valueStream: {
            ...valueStream,
            measurements: { ...valueStream.measurements }
          },
          appData: { ...appValueStream.appData },
        })

        break;
      }
    }

    await window.miro.board.setAppData(`unfix_valueStreams_${syncedBase.id}`, newValueStreamsAppData);
  }

  useEffect(() => {
    const doSync = async () => {
      const newValueStreamsAppData = await syncValueStreams(valueStreamsQuery.data);
      await syncValueStreamNameAndType(valueStreamsQuery.data, newValueStreamsAppData);
    }

    if (valueStreamsQuery.isSuccess && !valueStreamsQuery.isFetching && valueStreamsQuery.data) {
      doSync();
    }
  }, [
    valueStreamsQuery.data,
    valueStreamsQuery.isSuccess,
    valueStreamsQuery.isFetching,
  ]);

  return (null);
}
