import { useCallback, useEffect } from "react";
import { useContext } from "react";
import useBaseApi from "../../../apiHooks/useBaseApi"
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { FetchedBaseType } from "../../../common/types";
import UnfixBase from "./UnfixBase";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import { ItemsUpdateEvent, SelectionUpdateEvent } from "@mirohq/websdk-types"

import { SyncedBaseIdType } from "../sync/SyncLogic";
import Spinner from "../../utils/Spinner";

type MiroDropEventType = {
  x: number;
  y: number;
  target: HTMLElement;
}

export type DrawBaseType = {
  base: FetchedBaseType;
  x: number;
  y: number;
}

type BaseListProps = {
  baseIds: SyncedBaseIdType[],
  setBaseIds: (baseIds: SyncedBaseIdType[]) => void;
}

export default function BaseList({ baseIds, setBaseIds }: BaseListProps) {

  useIsAuthenticated();

  const { user } = useContext(AuthContext) as AuthContextType;
  const { basesQuery } = useBaseApi({ user });

  // const handleElementUpdate = useCallback(async (event: ItemsUpdateEvent) => {
  //   console.log("update", event);
  // }, []);

  // const handleSelectionUpdate = useCallback(async (event: SelectionUpdateEvent) => {
  //   console.log("selected", event);

  //   for (let item of event.items) {
  //     console.log(item, await item.getMetadata());
  //   }
  // }, []);

  const handleDrop = useCallback(async ({ x, y, target }: MiroDropEventType) => {
    // draw base

    if (!target.dataset?.base) {
      return;
    }

    const base: FetchedBaseType = JSON.parse(target.dataset.base);
    if (!base.display_options?.canvas_size.width || !base.display_options?.canvas_size.width) {
      return;
    }
    const baseLeft = x - (base.display_options.canvas_size.width / 2);
    const baseTop = y - (base.display_options.canvas_size.height / 2);

    let baseIds = await window.miro.board.getAppData("unfix_base_ids");
    if (!baseIds) {
      baseIds = [];
    }

    // @ts-ignore
    for (let baseId of baseIds) {
      // @ts-ignore
      if (baseId.id === base.id) {
        return;
      }
    }

    // @ts-ignore
    baseIds.push({
      id: base.id,
      name: base.name,
      x,
      y,
      left: baseLeft,
      top: baseTop,
    });

    // @ts-ignore
    setBaseIds(baseIds);
    await window.miro.board.setAppData("unfix_base_ids", baseIds);


  }, []);

  useEffect(() => {

    if (!!user) {
      window.miro.board.ui.on('drop', handleDrop);
      // window.miro.board.ui.on('experimental:items:update', handleElementUpdate);
      // window.miro.board.ui.on('selection:update', handleSelectionUpdate);
    }

    return () => {
      if (!!user) {
        window.miro.board.ui.off('drop', handleDrop);
        // window.miro.board.ui.off('experimental:items:update', handleElementUpdate);
        // window.miro.board.ui.off('selection:update', handleSelectionUpdate);
      }
    }
  }, [user]);

  const stopSync = async (baseId: string) => {
    let newBaseList = baseIds.filter((syncedBase) => syncedBase.id !== baseId);

    setBaseIds(newBaseList);
    await window.miro.board.setAppData("unfix_base_ids", newBaseList);
    await window.miro.board.setAppData(`unfix_base_${baseId}`, {});
    await window.miro.board.setAppData(`unfix_crews_${baseId}`, []);
    await window.miro.board.setAppData(`unfix_forums_${baseId}`, []);
    await window.miro.board.setAppData(`unfix_turfs_${baseId}`, []);
  }


  return (
    <div className="grid wrapper" style={{marginBottom: "3em"}}>
      <div className="cs1 ce12">
        <h1 className="h1">Synced Bases</h1>
        <div>
          {baseIds.map((baseId) => (
            <div key={`synced_base_id_${baseId.id}`} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <span>{baseId.name}</span>
              {/* <span > X</span> */}
              <button onClick={() => { stopSync(baseId.id) }} className="button button-danger button-small" type="button" aria-label="label">
                <span className="icon-close"></span>
              </button>
            </div>))}
          {!baseIds.length && <span>No items...</span>}
        </div>
      </div>
      <div className="cs1 ce12">
        <h1 className="h1">Bases</h1>
        <p className="p-small">Drag a Base to the board to draw / sync it.</p>
      </div>
      <div className="cs1 ce12">
        {basesQuery.isFetching && <Spinner text="Loading..." showText={true} />}
        {
          basesQuery.isSuccess && basesQuery.data.map((base: FetchedBaseType) => (
            <UnfixBase key={base.id} base={base} user={user} />
          ))
        }
      </div>
      {/* {drawingBase && <UnfixBase base={drawingBase.base} x={drawingBase.x} y={drawingBase.y} />} */}
    </div>
  );

}