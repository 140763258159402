import { Link } from "react-router-dom";

export default function Help() {
  return (
    <div className="grid wrapper" style={{ marginBottom: "3em" }}>
      <div className="cs1 ce12" style={{
        position: "absolute",
        top: "0px",
        backgroundColor: "white",
        width: "100%",
        // paddingTop: "1em",
        height: "2em",
        zIndex: "200",
      }}>
        <Link className="link link-primary" to="/bases/">Home</Link>
      </div>
      <div className="cs1 ce12" style={{marginTop: "2em"}}>
        <h1 className="h1">Help</h1>
        <p>This application is another interface to the <a className="link link-primary" href="https://unfix.com/plotter" target="_blank" rel="noreferrer">unFIX Plotter</a>, but this time in Miro. It enables you to draw draw your organization with the <a className="link link-primary" href="https://unfix.com" target="_blank" rel="noreferrer">unFIX Pattern Library</a>.</p>
        <p>After logging in, you can drag n' drop Bases to your Miro boards. Once you've done that, the Base will be automatically kept in sync with the Plotter database, so any changes there will be mirrored on the Miro board as well.</p>
        <p>You are welcome to rearrange your Base on the Miro board. Just click on any of the Crews / Forums / Turfs, and any related attributes will be selected altogether. Changes in alignment will not be synchronized back to the Plotter.</p>
        <p>You can also add, edit and delete Crews, Forums and Turfs directly from Miro.</p>
        <p>In case you have any questions, you're welcome to reach out on the unFIX Community Slack workspace, Circle or via apps@unfix.com.</p>
      </div>
    </div >
  )
}
