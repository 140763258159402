import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useForumApi from "../../../apiHooks/useForumApi";
import { DBForumType } from "../../../common/types";
import { drawForum } from "./../lib/draw";
import { forum_type_attributes } from "../../entities/Forum";
import { Shape } from "@mirohq/websdk-types";
import { SyncedBaseIdType } from "./SyncLogic";

type ForumMiroData = {
  shapeId: string;
}

type ForumAppData = {
  forum: DBForumType;
  appData: ForumMiroData;
}

type SyncedForumsProps = {
  syncedBase: SyncedBaseIdType;
}

export default function SyncedForums({ syncedBase }: SyncedForumsProps) {
  const { user } = useContext(AuthContext) as AuthContextType;
  const { forumsQuery } = useForumApi({ user, baseId: syncedBase.id });

  const copyForumAppData = (forum: ForumAppData) => {
    return {
      forum: {
        ...forum.forum,
        measurements: { ...forum.forum.measurements }
      },
      appData: { ...forum.appData },
    };
  };

  const syncForums = async (forums: DBForumType[]) => {

    // read last saved state from board metadata

    let forumsAppData: ForumAppData[] = await window.miro.board.getAppData(`unfix_forums_${syncedBase.id}`);

    if (!forumsAppData) {
      forumsAppData = [];
    }

    let foundForums: ForumAppData[] = [];
    let toCreate: DBForumType[] = [];
    let createdForums: ForumAppData[] = [];
    let deletedFromBoard: ForumAppData[] = [];
    let processedIds: string[] = [];

    // iterate over forums from the db to see if we can find them in the metadata
    // and on the board.

    for (let forum of forums) {
      let found: boolean = false;
      for (let appForum1 of forumsAppData) {
        if (forum.id === appForum1.forum.id) {
          found = true;
          processedIds.push(appForum1.forum.id);

          try {
            const shape = await window.miro.board.getById(appForum1.appData.shapeId);
            foundForums.push(copyForumAppData(appForum1));
          } catch (e) {
            console.log("Forum was deleted from the board", appForum1.forum.name);
            deletedFromBoard.push(copyForumAppData(appForum1));
          }
        }
      }

      // if the forum wasn't found in the metadata, then we will have to create it on the board
      if (!found) {
        toCreate.push({
          ...forum,
          measurements: { ...forum.measurements }
        })
      }
    }

    // if a forum was found in the metadata, but not on the board, then we will redraw it
    for (let i = 0; i < deletedFromBoard.length; i++) {
      let miroData = await drawForum(deletedFromBoard[i].forum, syncedBase.left, syncedBase.top);
      deletedFromBoard[i].appData = {
        shapeId: miroData.shapeId,
      };
    }

    // creating new forums
    for (let i = 0; i < toCreate.length; i++) {
      let miroData = await drawForum(toCreate[i], syncedBase.left, syncedBase.top);

      createdForums.push(copyForumAppData({
        forum: toCreate[i],
        appData: miroData,
      }))
    }

    // if deleting a forum wasn't synced to a board yet, it's te to do it
    // we check all the forums in the metadata, to see if they have a corresponding db entry
    // and if not, we delete them from the board
    for (let i = 0; i < forumsAppData.length; i++) {
      let found: boolean = false;
      for (let processedId of processedIds) {
        if (forumsAppData[i].forum.id === processedId) {
          found = true;
          break;
        }
      }

      if (!found) {
        try {
          console.log("deleting: ", forumsAppData[i].forum.name);

          // @ts-ignore
          const shape: Shape = await window.miro.board.getById(forumsAppData[i].appData.shapeId);
          const metaData = await shape.getMetadata("unfix");

          // @ts-ignore
          for (let componentId of metaData.toSelect) {
            try {
              const component = await window.miro.board.getById(componentId);
              await window.miro.board.remove(component);
            } catch (e) {
              continue;
            }
          }
        } catch (e) {
          console.log("Could not delete");
        }

      }
    }

    // save the synced state
    const newForumsAppData: ForumAppData[] = [...foundForums, ...deletedFromBoard, ...createdForums];

    await window.miro.board.setAppData(`unfix_forums_${syncedBase.id}`, newForumsAppData);

    return newForumsAppData;
  }

  const syncForumNameAndType = async (forums: DBForumType[], forumsAppData: ForumAppData[]) => {
    let newForumsAppData: ForumAppData[] = [];
    for (let forum of forums) {
      for (let appForum of forumsAppData) {
        if (forum.id !== appForum.forum.id) {
          continue;
        }

        let textId = "";
        let imageId = "";
        let shape = null;
        let metaData;
        // let ty

        try {
          shape = await window.miro.board.getById(appForum.appData.shapeId);

          // @ts-ignore
          metaData = await shape.getMetadata("unfix");
          textId = metaData.textId;
          imageId = metaData.imageId;

        } catch (e) {
          console.log("Couldn't find forum shape to sync");
        }

        // if the type of the forum was changed in the meantime
        // we change the color and the type image

        if (shape && forum.type != appForum.forum.type) {
          // @ts-ignore
          shape.style = {
            // @ts-ignore
            ...shape.style,
            borderColor: forum_type_attributes[forum.type]["color"],
            fillColor: '#FFFFFF',
          }

          await shape.sync();

          // image url can't be updated so we have to delete the image
          // and create the new one for the new type
          if (imageId) {
            try {
              let imageItem = await window.miro.board.getById(imageId);
              await window.miro.board.remove(imageItem);

              const iconFile = forum_type_attributes[forum.type]["icon"];
              const newImage = await window.miro.board.createImage({
                url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/forum/${iconFile}`,
                //@ts-ignore
                x: imageItem.x,
                //@ts-ignore
                y: imageItem.y,
                //@ts-ignore
                width: imageItem.width,
              });

              metaData.imageId = newImage.id;
              let newToSelect = metaData.toSelect.map((toSelect: string) => toSelect == imageItem.id ? newImage.id : toSelect);
              metaData.toSelect = [...newToSelect];
              // @ts-ignore
              await shape.setMetadata("unfix", metaData);

            } catch (e) {
              console.log("Couldn't find image shape");
            }
          }
        }

        // check if forum name is the same on in the db and on the board
        if (textId) {
          try {
            const textItem = await window.miro.board.getById(textId);
            if (textItem && "content" in textItem && textItem.content !== forum.name) {
              textItem.content = forum.name;
              await textItem.sync();
            }
          } catch (e) {
            console.log("Couldn't find forum shape");
          }
        }

        // save new modifications to the board
        newForumsAppData.push({
          forum: {
            ...forum,
            measurements: { ...forum.measurements }
          },
          appData: { ...appForum.appData },
        })

        break;
      }
    }

    await window.miro.board.setAppData(`unfix_forums_${syncedBase.id}`, newForumsAppData);
  }

  useEffect(() => {
    const doSync = async () => {
      const newForumsAppData = await syncForums(forumsQuery.data);
      await syncForumNameAndType(forumsQuery.data, newForumsAppData);
    }

    if (forumsQuery.isSuccess && !forumsQuery.isFetching && forumsQuery.data) {
      doSync();
    }
  }, [
    forumsQuery.data,
    forumsQuery.isSuccess,
    forumsQuery.isFetching,
  ]);

  return (null);
}
