
export const VALUE_STREAM_CREW_TYPE: number = 1;
const GOVERNANCE_CREW_TYPE: number = 2;
const PLATFORM_CREW_TYPE: number = 3;
const FACILITATION_CREW_TYPE: number = 4;
const CAPABILITY_CREW_TYPE: number = 5;
const EXPERIENCE_CREW_TYPE: number = 6;
const PARTNERSHIP_CREW_TYPE: number = 7;

export const CREW_TYPES = [
  { id: VALUE_STREAM_CREW_TYPE, name: "Value Stream Crew" },
  { id: GOVERNANCE_CREW_TYPE, name: "Governance Crew" },
  { id: PLATFORM_CREW_TYPE, name: "Platform Crew" },
  { id: FACILITATION_CREW_TYPE, name: "Facilitation Crew" },
  { id: CAPABILITY_CREW_TYPE, name: "Capability Crew" },
  { id: EXPERIENCE_CREW_TYPE, name: "Experience Crew" },
  { id: PARTNERSHIP_CREW_TYPE, name: "Partnership Crew" },
];

export const crew_type_attributes = {
  [VALUE_STREAM_CREW_TYPE]: {
    color: "#FAD45A",
    icon: "value_stream.svg",
    vertical: false,
  },
  [GOVERNANCE_CREW_TYPE]: {
    color: "#5ABDFA",
    icon: "governance.svg",
    vertical: false,
  },
  [PLATFORM_CREW_TYPE]: {
    color: "#5AFA84",
    icon: "platform.svg",
    vertical: false,
  },
  [FACILITATION_CREW_TYPE]: {
    color: "#715AFA",
    icon: "facilitation.svg",
    vertical: true,
  },
  [CAPABILITY_CREW_TYPE]: {
    color: "#FA5A5A",
    icon: "capability.svg",
    vertical: true,
  },
  [EXPERIENCE_CREW_TYPE]: {
    color: "#FA5AD0",
    icon: "experience.svg",
    vertical: true,
  },
  [PARTNERSHIP_CREW_TYPE]: {
    color: "#E7FA5A",
    icon: "partnership.svg",
    vertical: true,
  },
};

const INVESTMENT_HORIZON_MAINTENANCE: number = 1;
const INVESTMENT_HORIZON_IMPROVEMENT: number = 2;
const INVESTMENT_HORIZON_EXTENSION: number = 3;
const INVESTMENT_HORIZON_DISRUPTION: number = 4;

export const INVESTMENT_HORIZONS = [
  { id: INVESTMENT_HORIZON_MAINTENANCE, name: "Maintenance" },
  { id: INVESTMENT_HORIZON_IMPROVEMENT, name: "Improvement" },
  { id: INVESTMENT_HORIZON_EXTENSION, name: "Extension" },
  { id: INVESTMENT_HORIZON_DISRUPTION, name: "Disruption" },
];

export const investment_horizon_attributes = {
  [INVESTMENT_HORIZON_MAINTENANCE]: {
    icon: "investment_horizon_maintenance.png"
  },
  [INVESTMENT_HORIZON_IMPROVEMENT]: {
    icon: "investment_horizon_improvement.png"
  },
  [INVESTMENT_HORIZON_EXTENSION]: {
    icon: "investment_horizon_extension.png"
  },
  [INVESTMENT_HORIZON_DISRUPTION]: {
    icon: "investment_horizon_disruption.png"
  },
};


export function Crew() {

  return (
    <></>
  );
}
