import useCrewApi from "../../../apiHooks/useCrewApi";
import { FetchedBaseType } from "../../../common/types"
import { User } from "firebase/auth";
import useForumApi from "../../../apiHooks/useForumApi";
import useTurfApi from "../../../apiHooks/useTurfApi";
import { Link } from "react-router-dom";
import Spinner from "../../utils/Spinner";
import useValueStreamApi from "../../../apiHooks/useValueStreamApi";

type UnfixBasePropsType = {
  base: FetchedBaseType,
  user: User | null,
}

export default function UnfixBase({ base, user }: UnfixBasePropsType) {
  const { crewsQuery } = useCrewApi({ baseId: base.id, user });
  const { forumsQuery } = useForumApi({ baseId: base.id, user });
  const { turfsQuery } = useTurfApi({ baseId: base.id, user });
  const { valueStreamsQuery } = useValueStreamApi({ baseId: base.id, user });

  return (
    <div
      key={base.id}
      className={`app-card ${(crewsQuery.isSuccess && forumsQuery.isSuccess) ? "miro-draggable" : ""}`}
      data-base={JSON.stringify(base)}
      data-value-streams={valueStreamsQuery.data ? JSON.stringify(valueStreamsQuery.data) : "[]"}
      data-crews={crewsQuery.data ? JSON.stringify(crewsQuery.data) : "[]"}
      data-forums={forumsQuery.data ? JSON.stringify(forumsQuery.data) : "[]"}
      data-turfs={turfsQuery.data ? JSON.stringify(turfsQuery.data) : "[]"}
      style={{ cursor: "grab" }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h1 className="app-card--title">{base.name}</h1>
        <Link to={`/base/${base.id}`} type="button" className="button button-tertiary button-small" style={{cursor: "pointer"}}>
          <span className="icon-eye"></span>
        </Link>
      </div>
      <div className="app-card--body">
        <div className="app-card--tags">
          {!(
            crewsQuery.isSuccess &&
            forumsQuery.isSuccess &&
            turfsQuery.isSuccess &&
            valueStreamsQuery.isSuccess
            ) && <span className="tag" style={{ marginRight: "5px" }}><Spinner text="Loading..." showText={true} /></span>}
          {valueStreamsQuery.data && <span className="tag" style={{ marginRight: "5px" }}>{valueStreamsQuery.data.length} Value Streams </span>}
          {crewsQuery.data && <span className="tag" style={{ marginRight: "5px" }}>{crewsQuery.data.length} Crews </span>}
          {forumsQuery.data && <span className="tag" style={{ marginRight: "5px" }}>{forumsQuery.data.length} Forums </span>}
          {turfsQuery.data && <span className="tag" style={{ marginRight: "5px" }}>{turfsQuery.data.length} Turfs </span>}
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="app-card--app-logo"></svg>
      </div>
    </div>
  );
}