import { useEffect, useState } from 'react';
// import './App.css';
import BaseList from './components/miro/base/BaseList';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import SignIn from './pages/SignIn';
import Layout from './components/layout/Layout';
import SyncLogic, { SyncedBaseIdType } from './components/miro/sync/SyncLogic';
import CrewDetails from './components/miro/crew/CrewDetails';
import ForumDetails from './components/miro/forum/ForumDetails';
import BaseDetails from './components/miro/base/BaseDetails';
import TurfDetails from './components/miro/turf/TurfDetails';
import AddCrew from './components/miro/crew/AddCrew';
import AddForum from './components/miro/forum/AddForum';
import AddTurf from './components/miro/turf/AddTurf';
import Help from './pages/Help';
import DeleteCrewConfirm from './components/miro/modal/DeleteCrewConfirm';
import DeleteForumConfirm from './components/miro/modal/DeleteForumConfirm';
import DeleteTurfConfirm from './components/miro/modal/DeleteTurfConfirm';
import AddValueStream from './components/miro/valueStream/AddValueStream';
import ValueStreamDetails from './components/miro/valueStream/ValueStreamDetails';
import DeleteValueStreamConfirm from './components/miro/modal/DeleteValueStreamConfirm';

type ShapeMetaDataType = {
  toSelect: string[];
} | undefined;

export default function App() {

  // const location = useLocation();

  const [baseIds, setBaseIds] = useState<SyncedBaseIdType[]>([]);
  useEffect(() => {
    // @ts-ignore
    window.miro.board.ui.on('icon:click', async () => {
      // @ts-ignore
      await window.miro.board.ui.openPanel({ url: '/bases' });
    });

  }, [])

  return (
    <>
      {!window.location.href.includes("/delete") && <SyncLogic baseIds={baseIds} setBaseIds={setBaseIds} />}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="help" element={<Help />} />
            <Route path="bases" element={<BaseList baseIds={baseIds} setBaseIds={setBaseIds} />} />
            <Route path="sign_in" element={<SignIn />} />
            <Route path="base/:baseId" element={<BaseDetails />} />
            <Route path="base/:baseId/add_crew" element={<AddCrew />} />
            <Route path="base/:baseId/crew/:crewId" element={<CrewDetails />} />
            <Route path="base/:baseId/add_forum" element={<AddForum />} />
            <Route path="base/:baseId/forum/:forumId" element={<ForumDetails />} />
            <Route path="base/:baseId/add_turf" element={<AddTurf />} />
            <Route path="base/:baseId/turf/:turfId" element={<TurfDetails />} />
            <Route path="base/:baseId/add_value_stream" element={<AddValueStream />} />
            <Route path="base/:baseId/value_stream/:valueStreamId" element={<ValueStreamDetails />} />
            {/* <Route path="base/:baseId" element={<Main />} /> */}

          </Route>
          <Route path="/delete/:baseId/crew/:crewId" element={<DeleteCrewConfirm />} />
          <Route path="/delete/:baseId/forum/:forumId" element={<DeleteForumConfirm />} />
          <Route path="/delete/:baseId/turf/:turfId" element={<DeleteTurfConfirm />} />
          <Route path="/delete/:baseId/value_stream/:valueStreamId" element={<DeleteValueStreamConfirm />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
