
const FUNCTIONAL_FORUM_TYPE: number = 1;
const PRODUCT_FORUM_TYPE: number = 2;
const MARKET_FORUM_TYPE: number = 3;
const CHANNEL_FORUM_TYPE: number = 4;
const BUSINESS_MODEL_FORUM_TYPE: number = 5;
const CUSTOMER_JOURNEY_FORUM_TYPE: number = 6;
const REGIONAL_FORUM_TYPE: number = 7;
const TECHNOLOGICAL_FORUM_TYPE: number = 8;
const SEASONAL_FORUM_TYPE: number = 9;
const ORGANIZATIONAL_FORUM_TYPE: number = 10;

const FORUM_TYPES = [
  { id: FUNCTIONAL_FORUM_TYPE, name: "Functional Forum" },
  { id: PRODUCT_FORUM_TYPE, name: "Product Forum" },
  { id: MARKET_FORUM_TYPE, name: "Market Forum" },
  { id: CHANNEL_FORUM_TYPE, name: "Channel Forum" },
  { id: BUSINESS_MODEL_FORUM_TYPE, name: "Business Model Forum" },
  { id: CUSTOMER_JOURNEY_FORUM_TYPE, name: "Customer Journey Forum" },
  { id: REGIONAL_FORUM_TYPE, name: "Regional Forum" },
  { id: TECHNOLOGICAL_FORUM_TYPE, name: "Technological Forum" },
  { id: SEASONAL_FORUM_TYPE, name: "Seasonal Forum" },
  { id: ORGANIZATIONAL_FORUM_TYPE, name: "Organizational Forum" },
]

const forum_type_attributes = {
  [FUNCTIONAL_FORUM_TYPE]: {
    color: "#FDEDBB",
    icon: "functional.svg",
  },
  [PRODUCT_FORUM_TYPE]: {
    color: "#FDD4BB",
    icon: "product.svg",
  },
  [MARKET_FORUM_TYPE]: {
    color: "#BBE4FD",
    icon: "market.svg",
  },
  [CHANNEL_FORUM_TYPE]: {
    color: "#FDBBBB",
    icon: "channel.svg",
  },
  [BUSINESS_MODEL_FORUM_TYPE]: {
    color: "#BBFDFB",
    icon: "business_model.svg",
  },
  [CUSTOMER_JOURNEY_FORUM_TYPE]: {
    color: "#C4BBFD",
    icon: "customer_journey.svg",
  },
  [REGIONAL_FORUM_TYPE]: {
    color: "#C3FDBB",
    icon: "regional.svg",
  },
  [TECHNOLOGICAL_FORUM_TYPE]: {
    color: "#FDBBEC",
    icon: "technological.svg",
  },
  [SEASONAL_FORUM_TYPE]: {
    color: "#F5FDBB",
    icon: "seasonal.svg",
  },
  [ORGANIZATIONAL_FORUM_TYPE]: {
    color: "#EDC5FD",
    icon: "organizational.svg",
  },
}

function Forum() {
  return (
    <></>
  )
}

export { FORUM_TYPES, FUNCTIONAL_FORUM_TYPE, Forum, forum_type_attributes }
