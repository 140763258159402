import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useCrewApi from "../../../apiHooks/useCrewApi";
import { DBCrewType } from "../../../common/types";
import { drawCrew } from "./../lib/draw";
import { crew_type_attributes, investment_horizon_attributes } from "../../entities/Crew";
import { isEqual } from "lodash";
import { lifecycle_stage_attributes } from "../../entities/Base";
import { Shape } from "@mirohq/websdk-types";
import { SyncedBaseIdType } from "./SyncLogic";

type CrewMiroData = {
  shapeId: string;
}

type CrewAppData = {
  crew: DBCrewType;
  appData: CrewMiroData;
}

type SyncedCrewsProps = {
  syncedBase: SyncedBaseIdType;
}

export default function SyncedCrews({ syncedBase }: SyncedCrewsProps) {
  const { user } = useContext(AuthContext) as AuthContextType;
  const { crewsQuery } = useCrewApi({ user, baseId: syncedBase.id });

  const copyCrewAppData = (crew: CrewAppData) => {
    return {
      crew: {
        ...crew.crew,
        investmentHorizons: crew.crew.investmentHorizons?.length ? [...crew.crew.investmentHorizons] : [],
        measurements: { ...crew.crew.measurements }
      },
      appData: { ...crew.appData },
    };
  };

  const syncCrews = async (crews: DBCrewType[]) => {

    // read last saved state from board metadata

    let crewsAppData: CrewAppData[] = await window.miro.board.getAppData(`unfix_crews_${syncedBase.id}`);

    if (!crewsAppData) {
      crewsAppData = [];
    }

    let foundCrews: CrewAppData[] = [];
    let toCreate: DBCrewType[] = [];
    let createdCrews: CrewAppData[] = [];
    let deletedFromBoard: CrewAppData[] = [];
    let processedIds: string[] = [];

    // iterate over crews from the db to see if we can find them in the metadata
    // and on the board.

    for (let crew of crews) {
      let found: boolean = false;
      for (let appCrew1 of crewsAppData) {
        if (crew.id === appCrew1.crew.id) {
          found = true;
          processedIds.push(appCrew1.crew.id);

          try {
            const shape = await window.miro.board.getById(appCrew1.appData.shapeId);
            foundCrews.push(copyCrewAppData(appCrew1));
          } catch (e) {
            console.log("Crew was deleted from the board", appCrew1.crew.name);
            deletedFromBoard.push(copyCrewAppData(appCrew1));
          }
        }
      }

      // if the crew wasn't found in the metadata, then we will have to create it on the board
      if (!found) {
        toCreate.push({
          ...crew,
          investmentHorizons: crew.investmentHorizons?.length ? [...crew.investmentHorizons] : [],
          measurements: { ...crew.measurements }
        })
      }
    }

    // if a crew was found in the metadata, but not on the board, then we will redraw it
    for (let i = 0; i < deletedFromBoard.length; i++) {
      let miroData = await drawCrew(deletedFromBoard[i].crew, syncedBase.left, syncedBase.top);
      deletedFromBoard[i].appData = {
        shapeId: miroData.shapeId,
      };
    }

    // creating new crews
    for (let i = 0; i < toCreate.length; i++) {
      let miroData = await drawCrew(toCreate[i], syncedBase.left, syncedBase.top);

      createdCrews.push(copyCrewAppData({
        crew: toCreate[i],
        appData: miroData,
      }));
    }

    // if deleting a crew wasn't synced to a board yet, it's time to do it
    // we check all the crews in the metadata, to see if they have a corresponding db entry
    // and if not, we delete them from the board
    for (let i = 0; i < crewsAppData.length; i++) {
      let found: boolean = false;
      for (let processedId of processedIds) {
        if (crewsAppData[i].crew.id === processedId) {
          found = true;
          break;
        }
      }

      if (!found) {
        try {
          console.log("deleting: ", crewsAppData[i].crew.name);
          // @ts-ignore
          const shape: Shape = await window.miro.board.getById(crewsAppData[i].appData.shapeId);
          const metaData = await shape.getMetadata("unfix");

          // @ts-ignore
          for (let componentId of metaData.toSelect) {
            try {
              const component = await window.miro.board.getById(componentId);
              await window.miro.board.remove(component);
            } catch (e) {
              continue;
            }
          }
        } catch (e) {
          console.log("Could not delete");
        }

      }
    }

    // save the synced state
    const newCrewsAppData: CrewAppData[] = [...foundCrews, ...deletedFromBoard, ...createdCrews];

    await window.miro.board.setAppData(`unfix_crews_${syncedBase.id}`, newCrewsAppData);

    return newCrewsAppData;
  }

  const syncCrewNameAndType = async (crews: DBCrewType[], crewsAppData: CrewAppData[]) => {
    let newCrewsAppData: CrewAppData[] = [];
    for (let crew of crews) {
      for (let appCrew of crewsAppData) {
        if (crew.id !== appCrew.crew.id) {
          continue;
        }

        let textId = "";
        let imageId = "";
        let shape = null;
        let investmentHorizons: string[] = [];
        let metaData;
        // let ty

        try {
          shape = await window.miro.board.getById(appCrew.appData.shapeId);

          // @ts-ignore
          metaData = await shape.getMetadata("unfix");
          textId = metaData.textId;
          imageId = metaData.imageId;
          investmentHorizons = metaData.investmentHorizons;

        } catch (e) {
          console.log("Couldn't find crew shape to sync");
        }

        // if the type of the crew was changed in the meantime
        // we change the color and the type image

        if (shape && crew.type != appCrew.crew.type) {
          // @ts-ignore
          shape.style = {
            // @ts-ignore
            ...shape.style,
            borderColor: '#1a1a1a',
            fillColor: crew_type_attributes[crew.type]["color"],
          }

          await shape.sync();

          // image url can't be updated so we have to delete the image
          // and create the new one for the new type
          if (imageId) {
            try {
              let imageItem = await window.miro.board.getById(imageId);
              await window.miro.board.remove(imageItem);

              const iconFile = crew_type_attributes[crew.type]["icon"];
              const newImage = await window.miro.board.createImage({
                url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/crew/${iconFile}`,
                //@ts-ignore
                x: imageItem.x,
                //@ts-ignore
                y: imageItem.y,
                //@ts-ignore
                width: imageItem.width,
              });

              metaData.imageId = newImage.id;
              let newToSelect = metaData.toSelect.map((toSelect: string) => toSelect == imageItem.id ? newImage.id : toSelect);
              metaData.toSelect = [...newToSelect];
              // @ts-ignore
              await shape.setMetadata("unfix", metaData);

            } catch (e) {
              console.log("Couldn't find image shape");
            }
          }
        }

        // check if crew name is the same on in the db and on the board
        if (textId) {
          try {
            const textItem = await window.miro.board.getById(textId);
            if (textItem && "content" in textItem && textItem.content !== crew.name) {
              textItem.content = crew.name;
              await textItem.sync();
            }
          } catch (e) {
            console.log("Couldn't find crew shape");
          }
        }

        // check investment horizon differences and redraw if needed
        if (!isEqual(crew.investmentHorizons, appCrew.crew.investmentHorizons)) {
          for (let horizonItemtId of investmentHorizons) {
            try {
              const horizonItem = await window.miro.board.getById(horizonItemtId);
              await window.miro.board.remove(horizonItem);
            } catch (e) {
              console.log("Couldn't find horizon shape");
            }
          }

          if (crew.investmentHorizons && shape) {
            let newInvestmentHorizons: string[] = [];

            if (crew_type_attributes[crew.type]['vertical']) {
              const horizonCnt = crew.investmentHorizons.length;
              const widgetHalfWidth = (horizonCnt * 40) / 2;

              for (let i = 0; i < horizonCnt; i++) {
                let iconFile = investment_horizon_attributes[crew.investmentHorizons[i]]["icon"];
                let horizonImage = await window.miro.board.createImage({
                  url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/investment_horizon/${iconFile}`,
                  // @ts-ignore
                  x: shape.x - widgetHalfWidth + 20 + (i * 40),
                  // @ts-ignore
                  y: shape.y - (shape.height / 2) + 40,
                  width: 40, // Set either 'width', or 'height'
                });

                newInvestmentHorizons.push(horizonImage.id);
              }

            } else {
              let leftPadding = 0;
              for (let horizon of crew.investmentHorizons) {
                let iconFile = investment_horizon_attributes[horizon]["icon"];
                let horizonImage = await window.miro.board.createImage({
                  url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/investment_horizon/${iconFile}`,
                  // @ts-ignore
                  x: shape.x - (shape.width / 2) + leftPadding + 40,
                  // @ts-ignore
                  y: shape.y,
                  width: 40, // Set either 'width', or 'height'
                });

                leftPadding += 40;
                newInvestmentHorizons.push(horizonImage.id);
              }
            }

            metaData.investmentHorizons = [...newInvestmentHorizons];
            let newToSelect = metaData.toSelect.filter((toSelect: string) => !investmentHorizons.includes(toSelect));
            metaData.toSelect = [...newToSelect, ...newInvestmentHorizons];
            // @ts-ignore
            await shape.setMetadata("unfix", metaData);

          }
        }

        // check lifecycle stage difference and redraw if needed
        if (crew.lifecycleStage !== appCrew.crew.lifecycleStage) {
          if (metaData.lifecycleStageShapeId) {
            try {
              let lifecycleStageShape = await window.miro.board.getById(metaData.lifecycleStageShapeId);
              await window.miro.board.remove(lifecycleStageShape);

              metaData.lifecycleStageShapeId = "";
              let newToSelect = metaData.toSelect.filter((toSelect: string) => toSelect != lifecycleStageShape.id);
              metaData.toSelect = [...newToSelect];
            } catch (e) {
              console.log("Couldn't find lifecycle stage shape");
            }
          }

          if (shape && crew.lifecycleStage) {
            let stageShape;
            if (crew_type_attributes[crew.type]['vertical']) {
              stageShape = await window.miro.board.createShape({
                shape: "circle",
                content: `${crew.lifecycleStage}`,
                // @ts-ignore
                x: shape.x,
                // @ts-ignore
                y: shape.y - (shape.height / 2) + 100,
                width: 40,
                height: 40,
                style: {
                  color: "#FFFFFF",
                  borderColor: "#1a1a1a",
                  fillColor: lifecycle_stage_attributes[crew.lifecycleStage]["color"]
                }
              })
            } else {
              stageShape = await window.miro.board.createShape({
                shape: "circle",
                content: `${crew.lifecycleStage}`,
                // @ts-ignore
                x: shape.x - (shape.width / 2) + 100,
                // @ts-ignore
                y: shape.y,
                width: 40,
                height: 40,
                style: {
                  color: "#FFFFFF",
                  borderColor: "#1a1a1a",
                  fillColor: lifecycle_stage_attributes[crew.lifecycleStage]["color"]
                }
              })

            }

            metaData.lifecycleStageShapeId = stageShape.id;
            metaData.toSelect.push(stageShape.id);
          }

          // @ts-ignore
          await shape.setMetadata("unfix", metaData);
        }

        // save new modifications to the board
        newCrewsAppData.push({
          crew: {
            ...crew,
            investmentHorizons: crew.investmentHorizons?.length ? [...crew.investmentHorizons] : [],
            measurements: { ...crew.measurements }
          },
          appData: { ...appCrew.appData },
        })

        break;
      }
    }

    await window.miro.board.setAppData(`unfix_crews_${syncedBase.id}`, newCrewsAppData);
  }

  useEffect(() => {
    const doSync = async () => {
      const newCrewsAppData = await syncCrews(crewsQuery.data);
      await syncCrewNameAndType(crewsQuery.data, newCrewsAppData);
    }

    if (crewsQuery.isSuccess && !crewsQuery.isFetching && crewsQuery.data) {
      doSync();
    }
  }, [
    crewsQuery.data,
    crewsQuery.isSuccess,
    crewsQuery.isFetching,
  ]);

  return (null);
}
