import { CrewMetaDataType, DBCrewType, DBForumType, DBTurfType, DBValueStreamType, FetchedBaseType, ValueStreamMetaDataType } from "../../../common/types";
import { base_type_attributes, lifecycle_stage_attributes } from "../../entities/Base";
import { crew_type_attributes, investment_horizon_attributes } from "../../entities/Crew";
import { forum_type_attributes } from "../../entities/Forum";
import { turf_type_attributes } from "../../entities/Turf";
import { valueStream_type_attributes } from "../../entities/ValueStream";

export async function drawBase(x: number, y: number, base: FetchedBaseType) {
  if (!base.display_options?.canvas_size.width || !base.display_options?.canvas_size.width) {
    return;
  }

  const baseFrame = await window.miro.board.createFrame({
    title: base.name,
    x,
    y,
    width: base.display_options.canvas_size.width,
    height: base.display_options.canvas_size.height
  });

  const shorterCanvasSide = Math.min(base.display_options.canvas_size.width, base.display_options.canvas_size.height);

  const baseShape = await window.miro.board.createShape({
    shape: "circle",
    x,
    y,
    width: shorterCanvasSide - 40,
    height: shorterCanvasSide - 40,
    style: {
      borderColor: '#1a1a1a',
      // @ts-ignore
      borderStyle: base_type_attributes[base.type]["border"],
    }
  });

  await baseFrame.add(baseShape);

  console.log(`${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/base.svg`);
  let baseIcon = await window.miro.board.createImage({
    url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/base.svg`,
    x,
    y: y + ((shorterCanvasSide - 40) / 2) - 60,
    width: 40, // Set either 'width', or 'height'
  });

  await baseFrame.add(baseIcon);

  return {
    frameId: baseFrame.id,
    shapeId: baseShape.id,
    iconId: baseIcon.id,
  }
}

export async function drawTurf(turf: DBTurfType, baseLeft: number, baseTop: number) {
  let turfLeft = baseLeft + turf.measurements.x;
  let turfTop = baseTop + turf.measurements.y;
  let turfWidth = turf.measurements.width;
  let turfHeight = turf.measurements.height;
  let turfRight = turfLeft + turfWidth;
  let turfBottom = turfTop + turfHeight;
  let turfXCenter = turfLeft + turfWidth / 2;
  let turfYCenter = turfTop + turfHeight / 2;

  let turfShape = await window.miro.board.createShape({
    shape: "rectangle",
    x: turfXCenter,
    y: turfYCenter,
    width: Math.max(turfWidth, 100),
    height: Math.max(turfHeight, 100),
    style: {
      borderWidth: 3,
      borderColor: turf_type_attributes[turf.type]["color"],
      fillColor: '#d6d3d1',
    }
  });

  let turfText = await window.miro.board.createText({
    content: `<p>${turf.name}</p>`,
    style: {
      fontFamily: 'arial', // Default font type for the text
      fontSize: 14, // Default font size for the text
      textAlign: 'center',
    },
    x: turfXCenter,
    y: turfTop + 40,
    width: turfWidth - 20,
  });

  let iconFile = turf_type_attributes[turf.type]["icon"];

  let turfImage = await window.miro.board.createImage({
    url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/turf/${iconFile}`,
    x: turfXCenter,
    y: turfBottom - 60,
    width: 40, // Set either 'width', or 'height'
  });

  await turfShape.setMetadata("unfix", {
    entity: "turf",
    entityId: turf.id,
    baseId: turf.base,
    shapeId: turfShape.id,
    textId: turfText.id,
    imageId: turfImage.id,
    toSelect: [turfShape.id, turfText.id, turfImage.id],
  });

  return {
    shapeId: turfShape.id,
    textId: turfText.id,
    imageId: turfImage.id,
  }
}

export async function drawTurfs(turfs: DBTurfType[], baseLeft: number, baseTop: number) {
  // for (let i in turfs) {
  //   await drawTurf(turfs[i], baseLeft, baseTop);
  // }
}

export async function drawForum(forum: DBForumType, baseLeft: number, baseTop: number) {
  let forumLeft = baseLeft + forum.measurements.x;
  let forumTop = baseTop + forum.measurements.y;
  let forumWidth = forum.measurements.width;
  let forumHeight = forum.measurements.height;
  let forumRight = forumLeft + forumWidth;
  let forumBottom = forumTop + forumHeight;
  let forumXCenter = forumLeft + forumWidth / 2;
  let forumYCenter = forumTop + forumHeight / 2;

  let forumShape = await window.miro.board.createShape({
    shape: "round_rectangle",
    x: forumXCenter,
    y: forumYCenter,
    width: Math.max(forumWidth, 100),
    height: Math.max(forumHeight, 100),
    style: {
      borderWidth: 10,
      borderColor: forum_type_attributes[forum.type]["color"],
      fillColor: '#FFFFFF',
    }
  });

  let forumText = await window.miro.board.createText({
    content: `<p>${forum.name}</p>`,
    style: {
      fontFamily: 'arial', // Default font type for the text
      fontSize: 14, // Default font size for the text
      textAlign: 'center', // Default horizontal alignment for the text
    },
    x: forumXCenter,
    y: forumTop + 40,
    width: forumWidth - 20,
  });

  let iconFile = forum_type_attributes[forum.type]["icon"];

  let forumImage = await window.miro.board.createImage({
    url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/forum/${iconFile}`,
    x: forumXCenter,
    y: forumBottom - 60,
    width: 40, // Set either 'width', or 'height'
  });

  await forumShape.setMetadata("unfix", {
    entity: "forum",
    entityId: forum.id,
    baseId: forum.base,
    shapeId: forumShape.id,
    textId: forumText.id,
    imageId: forumImage.id,
    toSelect: [forumShape.id, forumText.id, forumImage.id],
  });

  return {
    shapeId: forumShape.id,
    textId: forumText.id,
    imageId: forumImage.id,
  }
}

export async function drawForums(forums: DBForumType[], baseLeft: number, baseTop: number) {
  for (let i in forums) {
    await drawForum(forums[i], baseLeft, baseTop);
  }
}

export async function drawCrew(crew: DBCrewType, baseLeft: number, baseTop: number) {
  const isVertical = crew_type_attributes[crew.type]['vertical'];

  if (isVertical) {
    return drawVerticalCrew(crew, baseLeft, baseTop);
  } else {
    return drawHorizontalCrew(crew, baseLeft, baseTop);
  }
}

export async function drawHorizontalCrew(crew: DBCrewType, baseLeft: number, baseTop: number) {
  let crewLeft = baseLeft + crew.measurements.x;
  let crewTop = baseTop + crew.measurements.y;
  let crewWidth = crew.measurements.width;
  let crewHeight = crew.measurements.height;
  let crewRight = crewLeft + crewWidth;
  let crewBottom = crewTop + crewHeight;
  let crewXCenter = crewLeft + crewWidth / 2;
  let crewYCenter = crewTop + crewHeight / 2;

  let leftPadding = 0;

  let metaData: CrewMetaDataType = {
    entity: "crew",
    entityId: crew.id,
    baseId: crew.base,
    shapeId: "",
    textId: "",
    imageId: "",
    investmentHorizons: [],
    lifecycleStageShapeId: "",
    toSelect: [],
  };

  // create base crew shape
  let crewShape = await window.miro.board.createShape({
    shape: "round_rectangle",
    x: crewXCenter,
    y: crewYCenter,
    width: crewWidth,
    height: Math.max(crewHeight + 20, 100),
    style: {
      borderColor: '#1a1a1a',
      fillColor: crew_type_attributes[crew.type]["color"],
    }
  });

  metaData.shapeId = crewShape.id;
  metaData.toSelect.push(crewShape.id);

  // add investment horizons
  if (crew.investmentHorizons) {
    for (let horizon of crew.investmentHorizons) {
      let iconFile = investment_horizon_attributes[horizon]["icon"];
      let horizonImage = await window.miro.board.createImage({
        url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/investment_horizon/${iconFile}`,
        x: leftPadding + crewLeft + 40,
        y: crewYCenter,
        width: 40, // Set either 'width', or 'height'
      });

      leftPadding += 40;
      metaData.investmentHorizons.push(horizonImage.id);
      metaData.toSelect.push(horizonImage.id);
    }
  }

  // draw lifecycle stage
  if (crew.lifecycleStage) {
    let stageShape = await window.miro.board.createShape({
      shape: "circle",
      content: `${crew.lifecycleStage}`,
      x: leftPadding + crewLeft + 40,
      y: crewYCenter,
      width: 40,
      height: 40,      
      style: {
        color: "#FFFFFF",
        borderColor: "#1a1a1a",
        fillColor: lifecycle_stage_attributes[crew.lifecycleStage]["color"]
      }
    })

    leftPadding += 40;
    metaData.lifecycleStageShapeId = stageShape.id;
    metaData.toSelect.push(stageShape.id);
  }

  // crew name
  let crewText = await window.miro.board.createText({
    content: `<p>${crew.name}</p>`,
    style: {
      fontFamily: 'arial', // Default font type for the text
      fontSize: 14, // Default font size for the text
      textAlign: 'left', // Default horizontal alignment for the text
    },
    x: crewXCenter + (leftPadding / 2) + 20,
    y: crewYCenter,
    width: crewWidth - leftPadding - 40,
  });

  metaData.textId = crewText.id;
  metaData.toSelect.push(crewText.id);

  // crew type image
  let iconFile = crew_type_attributes[crew.type]["icon"];

  let crewImage;
  crewImage = await window.miro.board.createImage({
    url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/crew/${iconFile}`,
    x: crewRight - 60,
    y: crewYCenter,
    width: 40, // Set either 'width', or 'height'
  });

  metaData.imageId = crewImage.id;
  metaData.toSelect.push(crewImage.id);

  await crewShape.setMetadata("unfix", metaData)

  return {
    shapeId: crewShape.id,
    textId: crewText.id,
    imageId: crewImage.id,
  }

}

export async function drawVerticalCrew(crew: DBCrewType, baseLeft: number, baseTop: number) {
  let crewLeft = baseLeft + crew.measurements.x;
  let crewTop = baseTop + crew.measurements.y;
  let crewWidth = crew.measurements.width;
  let crewHeight = crew.measurements.height;
  let crewRight = crewLeft + crewWidth;
  let crewBottom = crewTop + crewHeight;
  let crewXCenter = crewLeft + crewWidth / 2;
  let crewYCenter = crewTop + crewHeight / 2;

  let topPadding = 0;

  let metaData: CrewMetaDataType = {
    entity: "crew",
    entityId: crew.id,
    baseId: crew.base,
    shapeId: "",
    textId: "",
    imageId: "",
    investmentHorizons: [],
    lifecycleStageShapeId: "",
    toSelect: [],
  };

  // create base crew shape
  let crewShape = await window.miro.board.createShape({
    shape: "round_rectangle",
    x: crewXCenter,
    y: crewYCenter,
    width: crewWidth,
    height: Math.max(crewHeight + 20, 100),
    style: {
      borderColor: '#1a1a1a',
      fillColor: crew_type_attributes[crew.type]["color"],
    }
  });

  metaData.shapeId = crewShape.id;
  metaData.toSelect.push(crewShape.id);

  // add investment horizons
  if (crew.investmentHorizons) {
    
    topPadding += 40;
    const horizonCnt = crew.investmentHorizons.length;
    const widgetHalfWidth = (horizonCnt * 40) / 2;

    for (let i = 0; i < horizonCnt; i++) {
      let iconFile = investment_horizon_attributes[crew.investmentHorizons[i]]["icon"];
      let horizonImage = await window.miro.board.createImage({
        url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/investment_horizon/${iconFile}`,
        x: crewXCenter - widgetHalfWidth + 20 + (i * 40),
        y: crewTop + 40,
        width: 40, // Set either 'width', or 'height'
      });

      metaData.investmentHorizons.push(horizonImage.id);
      metaData.toSelect.push(horizonImage.id);
    }
  }

  // draw lifecycle stage
  if (crew.lifecycleStage) {
    let stageShape = await window.miro.board.createShape({
      shape: "circle",
      content: `${crew.lifecycleStage}`,
      x: crewXCenter,
      y: crewTop + topPadding + 40,
      width: 40,
      height: 40,      
      style: {
        color: "#FFFFFF",
        borderColor: "#1a1a1a",
        fillColor: lifecycle_stage_attributes[crew.lifecycleStage]["color"]
      }
    })

    topPadding += 40;
    metaData.lifecycleStageShapeId = stageShape.id;
    metaData.toSelect.push(stageShape.id);
  }

  // crew name
  let crewText = await window.miro.board.createText({
    content: `<p>${crew.name}</p>`,
    style: {
      fontFamily: 'arial', // Default font type for the text
      fontSize: 14, // Default font size for the text
      textAlign: 'center', // Default horizontal alignment for the text
    },
    x: crewXCenter,
    y: crewTop + topPadding + 40,
    width: crewWidth - 20,
  });

  metaData.textId = crewText.id;
  metaData.toSelect.push(crewText.id);

  // crew type icon
  let iconFile = crew_type_attributes[crew.type]["icon"];

  let crewImage;
  crewImage = await window.miro.board.createImage({
    url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/crew/${iconFile}`,
    x: crewXCenter,
    y: crewBottom - 60,
    width: 40, // Set either 'width', or 'height'
  });

  metaData.imageId = crewImage.id;
  metaData.toSelect.push(crewImage.id);

  await crewShape.setMetadata("unfix", metaData);

  return {
    shapeId: crewShape.id,
    textId: crewText.id,
    imageId: crewImage.id,
  }

}

export async function drawCrews(crews: DBCrewType[], baseLeft: number, baseTop: number) {
  for (let i in crews) {
    await drawCrew(crews[i], baseLeft, baseTop);
  }
}

export async function drawValueStream(valueStream: DBValueStreamType, baseLeft: number, baseTop: number) {
  let valueStreamLeft = baseLeft + valueStream.measurements.x;
  let valueStreamTop = baseTop + valueStream.measurements.y;
  let valueStreamWidth = valueStream.measurements.width;
  let valueStreamHeight = valueStream.measurements.height;
  let valueStreamRight = valueStreamLeft + valueStreamWidth;
  let valueStreamBottom = valueStreamTop + valueStreamHeight;
  let valueStreamXCenter = valueStreamLeft + valueStreamWidth / 2;
  let valueStreamYCenter = valueStreamTop + valueStreamHeight / 2;

  let metaData: ValueStreamMetaDataType = {
    entity: "value_stream",
    entityId: valueStream.id,
    baseId: valueStream.base,
    shapeId: "",
    textId: "",
    lifecycleStageShapeId: "",
    imageId: "",
    toSelect: [],
  }

  let valueStreamShape = await window.miro.board.createShape({
    shape: "rectangle",
    x: valueStreamXCenter,
    y: valueStreamYCenter,
    width: Math.max(valueStreamWidth, 100),
    // height: Math.max(valueStreamHeight, 100),
    height: 50,
    style: {
      borderWidth: 1,
      borderColor: valueStream_type_attributes[valueStream.type]["color"],
      fillColor: valueStream_type_attributes[valueStream.type]["color"],
    }
  });

  metaData.shapeId = valueStreamShape.id;
  metaData.toSelect.push(valueStreamShape.id)

  
  let iconFile = valueStream_type_attributes[valueStream.type]["icon"];

  let valueStreamImage = await window.miro.board.createImage({
    url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/value_stream/${iconFile}`,
    x: valueStreamLeft + 40,
    y: valueStreamYCenter,
    width: 40, // Set either 'width', or 'height'
  });

  metaData.imageId = valueStreamImage.id;
  metaData.toSelect.push(valueStreamImage.id);

  let leftPadding = 60;

  if (valueStream.lifecycleStage) {
    let stageShape = await window.miro.board.createShape({
      shape: "circle",
      content: `${valueStream.lifecycleStage}`,
      x: valueStreamLeft + leftPadding + 40,
      y: valueStreamYCenter,
      width: 40,
      height: 40,      
      style: {
        color: "#FFFFFF",
        borderColor: "#1a1a1a",
        fillColor: lifecycle_stage_attributes[valueStream.lifecycleStage]["color"]
      }
    })

    leftPadding += 40;
    metaData.lifecycleStageShapeId = stageShape.id;
    metaData.toSelect.push(stageShape.id);
  }

  let valueStreamText = await window.miro.board.createText({
    content: `<p>${valueStream.name}</p>`,
    style: {
      fontFamily: 'arial', // Default font type for the text
      fontSize: 14, // Default font size for the text
      textAlign: 'left',
      color: '#FFFFFF',
    },
    x: valueStreamXCenter + 20,
    y: valueStreamTop + 50,
    width: valueStreamWidth - 240,
  });

  metaData.textId = valueStreamText.id;
  metaData.toSelect.push(valueStreamText.id);

  await valueStreamShape.setMetadata("unfix", metaData);

  return {
    shapeId: valueStreamShape.id,
    textId: valueStreamText.id,
    imageId: valueStreamImage.id,
  }
}
