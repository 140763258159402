import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useForumApi from "../../../apiHooks/useForumApi";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FORUM_TYPES } from "../../entities/Forum";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import Spinner from "../../utils/Spinner";

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function ForumDetails() {

  useIsAuthenticated();

  const navigate = useNavigate();

  const { baseId, forumId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  const removeSuccessCB = () => {
    navigate(`/base/${baseId}`);
  }

  const { forumQuery, editForum, removeForum } = useForumApi({ baseId: baseId ?? "", user, forumId, removeSuccessCB});

  return (
    // <div className="grid form-example">
    <div className="form-example" style={{ marginBottom: "3em" }}>
      <div style={{marginBottom: "1em"}}>
      <Link className="link link-primary" to="/bases/">Home</Link> / <Link className="link link-primary" to={`/base/${baseId}`}>Base</Link> / {forumQuery.isSuccess ? forumQuery.data.name : "Forum"}
      </div>
      {/* <form className="cs2 ce9 form-example--main-content"> */}
      <div style={{marginTop: "1em", marginBottom: "1em"}}>
        {forumQuery.isFetching && <Spinner text="Loading..." showText={true} />}
      </div>
      {!forumQuery.isFetching && forumQuery.data && <Formik
        enableReinitialize={true}
        initialValues={{ ...forumQuery.data }}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          values.type = Number(values.type);
          editForum(values);
          actions.setSubmitting(false);
          // actions.resetForm();
        }}
      >
        <Form id="forumEditForm" className="grid">
          <div className="cs1 ce10 form-group">
            <label htmlFor="name">Name</label>
            <Field name="name" placeholder="Forum name" className="input" />
            <ErrorMessage className="text-red-700" name="name" component="div" />
          </div>
          <div className="cs1 ce11 form-group">
            <label htmlFor="type">Type</label>
            <Field name="type" as="select" className="select">
              {FORUM_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
            </Field>
            <ErrorMessage className="text-red-700" name="type" component="div" />
          </div>
          <button type="submit" className="cs1 ce12 button button-primary">
            Save
          </button>
        </Form>

      </Formik>}
      <div className="grid" style={{ marginTop: "0.5rem" }}>
        <button type="button" onClick={() => {removeForum(forumId ?? "")}} className="cs1 ce12 button button-danger">
          Delete Forum
        </button>
      </div>
    </div>
  );
}
