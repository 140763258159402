import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useTurfApi from "../../../apiHooks/useTurfApi";
import { DBTurfType } from "../../../common/types";
import { drawTurf } from "./../lib/draw";
import { turf_type_attributes } from "../../entities/Turf";
import { Shape } from "@mirohq/websdk-types";
import { SyncedBaseIdType } from "./SyncLogic";

type TurfMiroData = {
  shapeId: string;
}

type TurfAppData = {
  turf: DBTurfType;
  appData: TurfMiroData;
}

type SyncedTurfsProps = {
  syncedBase: SyncedBaseIdType;
}

export default function SyncedTurfs({ syncedBase }: SyncedTurfsProps) {
  const { user } = useContext(AuthContext) as AuthContextType;
  const { turfsQuery } = useTurfApi({ user, baseId: syncedBase.id });

  const copyTurfAppData = (turf: TurfAppData) => {
    return {
      turf: {
        ...turf.turf,
        measurements: { ...turf.turf.measurements }
      },
      appData: { ...turf.appData },
    };
  };

  const syncTurfs = async (turfs: DBTurfType[]) => {

    // read last saved state from board metadata

    let turfsAppData: TurfAppData[] = await window.miro.board.getAppData(`unfix_turfs_${syncedBase.id}`);

    if (!turfsAppData) {
      turfsAppData = [];
    }

    let foundTurfs: TurfAppData[] = [];
    let toCreate: DBTurfType[] = [];
    let createdTurfs: TurfAppData[] = [];
    let deletedFromBoard: TurfAppData[] = [];
    let processedIds: string[] = [];

    // iterate over turfs from the db to see if we can find them in the metadata
    // and on the board.

    for (let turf of turfs) {
      let found: boolean = false;
      for (let appTurf1 of turfsAppData) {
        if (turf.id === appTurf1.turf.id) {
          found = true;
          processedIds.push(appTurf1.turf.id);

          try {
            const shape = await window.miro.board.getById(appTurf1.appData.shapeId);
            foundTurfs.push(copyTurfAppData(appTurf1));
          } catch (e) {
            console.log("Turf was deleted from the board", appTurf1.turf.name);
            deletedFromBoard.push(copyTurfAppData(appTurf1));
          }
        }
      }

      // if the turf wasn't found in the metadata, then we will have to create it on the board
      if (!found) {
        toCreate.push({
          ...turf,
          measurements: { ...turf.measurements }
        })
      }
    }

    // if a turf was found in the metadata, but not on the board, then we will redraw it
    for (let i = 0; i < deletedFromBoard.length; i++) {
      let miroData = await drawTurf(deletedFromBoard[i].turf, syncedBase.left, syncedBase.top);
      deletedFromBoard[i].appData = {
        shapeId: miroData.shapeId,
      };
    }

    // creating new turfs
    for (let i = 0; i < toCreate.length; i++) {
      let miroData = await drawTurf(toCreate[i], syncedBase.left, syncedBase.top);

      createdTurfs.push(copyTurfAppData({
        turf: toCreate[i],
        appData: miroData,
      }))
    }

    // if deleting a turf wasn't synced to a board yet, it's te to do it
    // we check all the turfs in the metadata, to see if they have a corresponding db entry
    // and if not, we delete them from the board
    for (let i = 0; i < turfsAppData.length; i++) {
      let found: boolean = false;
      for (let processedId of processedIds) {
        if (turfsAppData[i].turf.id === processedId) {
          found = true;
          break;
        }
      }

      if (!found) {
        try {
          console.log("deleting: ", turfsAppData[i].turf.name);

          // @ts-ignore
          const shape: Shape = await window.miro.board.getById(turfsAppData[i].appData.shapeId);
          const metaData = await shape.getMetadata("unfix");

          // @ts-ignore
          for (let componentId of metaData.toSelect) {
            try {
              const component = await window.miro.board.getById(componentId);
              await window.miro.board.remove(component);
            } catch (e) {
              continue;
            }
          }
        } catch (e) {
          console.log("Could not delete");
        }

      }
    }

    // save the synced state
    const newTurfsAppData: TurfAppData[] = [...foundTurfs, ...deletedFromBoard, ...createdTurfs];

    await window.miro.board.setAppData(`unfix_turfs_${syncedBase.id}`, newTurfsAppData);

    return newTurfsAppData;
  }

  const syncTurfNameAndType = async (turfs: DBTurfType[], turfsAppData: TurfAppData[]) => {
    let newTurfsAppData: TurfAppData[] = [];
    for (let turf of turfs) {
      for (let appTurf of turfsAppData) {
        if (turf.id !== appTurf.turf.id) {
          continue;
        }

        let textId = "";
        let imageId = "";
        let shape = null;
        let metaData;
        // let ty

        try {
          shape = await window.miro.board.getById(appTurf.appData.shapeId);

          // @ts-ignore
          metaData = await shape.getMetadata("unfix");
          textId = metaData.textId;
          imageId = metaData.imageId;

        } catch (e) {
          console.log("Couldn't find turf shape to sync");
        }

        // if the type of the turf was changed in the meantime
        // we change the color and the type image

        if (shape && turf.type != appTurf.turf.type) {
          // @ts-ignore
          shape.style = {
            // @ts-ignore
            ...shape.style,
            borderColor: turf_type_attributes[turf.type]["color"],
            fillColor: '#FFFFFF',
          }

          await shape.sync();

          // image url can't be updated so we have to delete the image
          // and create the new one for the new type
          if (imageId) {
            try {
              let imageItem = await window.miro.board.getById(imageId);
              await window.miro.board.remove(imageItem);

              const iconFile = turf_type_attributes[turf.type]["icon"];
              const newImage = await window.miro.board.createImage({
                url: `${process.env.REACT_APP_IMAGE_DOMAIN}/unfix/icons/turf/${iconFile}`,
                //@ts-ignore
                x: imageItem.x,
                //@ts-ignore
                y: imageItem.y,
                //@ts-ignore
                width: imageItem.width,
              });

              metaData.imageId = newImage.id;
              let newToSelect = metaData.toSelect.map((toSelect: string) => toSelect == imageItem.id ? newImage.id : toSelect);
              metaData.toSelect = [...newToSelect];
              // @ts-ignore
              await shape.setMetadata("unfix", metaData);

            } catch (e) {
              console.log("Couldn't find image shape");
            }
          }
        }

        // check if turf name is the same on in the db and on the board
        if (textId) {
          try {
            const textItem = await window.miro.board.getById(textId);
            if (textItem && "content" in textItem && textItem.content !== turf.name) {
              textItem.content = turf.name;
              await textItem.sync();
            }
          } catch (e) {
            console.log("Couldn't find turf shape");
          }
        }

        // save new modifications to the board
        newTurfsAppData.push({
          turf: {
            ...turf,
            measurements: { ...turf.measurements }
          },
          appData: { ...appTurf.appData },
        })

        break;
      }
    }

    await window.miro.board.setAppData(`unfix_turfs_${syncedBase.id}`, newTurfsAppData);
  }

  useEffect(() => {
    const doSync = async () => {
      const newTurfsAppData = await syncTurfs(turfsQuery.data);
      await syncTurfNameAndType(turfsQuery.data, newTurfsAppData);
    }

    if (turfsQuery.isSuccess && !turfsQuery.isFetching && turfsQuery.data) {
      doSync();
    }
  }, [
    turfsQuery.data,
    turfsQuery.isSuccess,
    turfsQuery.isFetching,
  ]);

  return (null);
}
