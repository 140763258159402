import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createTurf, deleteTurf, getTurf, getTurfs, patchTurf } from "../api/turfApi";
import { getTurfDefaultMeasurements } from "../components/canvas/lib/measurements"; 
import { DBTurfPatchType, DBTurfType, FormTurfType } from "../common/types";
import { User } from 'firebase/auth';

type useTurfApiParams = {
  baseId: string;
  user: User | null;
  addSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  patchSuccessCB?: () => void;
  turfId?: string;
}

export default function useTurfApi({ baseId, user, addSuccessCB, removeSuccessCB, patchSuccessCB, turfId }: useTurfApiParams) {
  const queryClient = useQueryClient();
  const turfQuery = useQuery(["turf", baseId, turfId],
    // @ts-ignore
    () => getTurf({ user, baseId, turfId }),
    { enabled: !!user && !!baseId && !!turfId });
  const turfsQuery = useQuery(["turfs", baseId], () => getTurfs({ user, baseId }), { enabled: !!user && !!baseId });
  const createTurfMutation = useMutation(createTurf);
  const deleteTurfMutation = useMutation(deleteTurf);
  const patchTurfMutation = useMutation(patchTurf);

  const addTurf = (formData: FormTurfType) => {
    let turf: DBTurfType = {
      ...formData,
      id: "",
      base: baseId,
      measurements: getTurfDefaultMeasurements(
        turfsQuery.data?.length,
        turfsQuery.data?.length + 1,
        1280,
        720,
      ),
    };

    createTurfMutation.mutate({ user, baseId, turf }, {
      onSuccess: (data: any) => {
        turf["id"] = data.id;
        let newList = [...turfsQuery.data, turf];
        queryClient.setQueryData(["turfs", baseId], newList);
        // queryClient.invalidateQueries(["turfs", baseId]);

        if (addSuccessCB) {
          addSuccessCB();
        }
      },
    });
  }

  const removeTurf = (turfId: string) => {
    // if (window.confirm("Are you sure you want to delete this Turf?") === true) {
      deleteTurfMutation.mutate({ user, baseId, turfId }, {
        onSuccess: () => {
          const newList = turfsQuery.data.filter((element: DBTurfType) => element.id !== turfId);
          queryClient.setQueryData(["turfs", baseId], newList);
          // queryClient.invalidateQueries(["turfs", baseId]);

          if (removeSuccessCB) {
            removeSuccessCB();
          }
        }
      });
    // }
  }

  const editTurf = (turf: DBTurfPatchType) => {
    if (!turfId) {
      return;
    }

    const toSave = {
      ...turf,
      id: turfId,
    };
    patchTurfMutation.mutate({user, baseId, turf: toSave}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["turf", baseId, turfId]);
        queryClient.invalidateQueries(["turfs", baseId]);

        if (patchSuccessCB) {
          patchSuccessCB();
        }
      }
    })
  }

  return {
    turfsQuery,
    turfQuery,
    createTurfMutation,
    addTurf,
    deleteTurfMutation,
    removeTurf,
    patchTurfMutation,
    editTurf,
  }

}
