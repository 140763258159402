import { auth } from "../firebase";
import { FULLY_INTEGRATED_BASE } from "../components/entities/Base";
import { User } from "firebase/auth";

type GetBasesParamsType = {
  user: User | null;
};

export async function getBases(params: GetBasesParamsType) {
  const {user} = params;

  const token = await user?.getIdToken();

  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/bases/`, {
    headers: {
      "Authorization": "" + token
    }
  });

  if (!response.ok) {
    throw new Error("Couldn't fetch base list");
  }

  return response.json();
}

type GetBaseParamsType = {
  user: User | null;
  baseId: string;
};

export async function getBase(params: GetBaseParamsType) {
  const {user, baseId} = params;
  const token = await user?.getIdToken();

  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/bases/${baseId}/`, {
    headers: {
      "Authorization": "" + token
    }
  });

  if (!response.ok) {
    throw new Error("Couldn't fetch base");
  }

  return response.json();
}

export async function createBase() {
  const unfixBase = {
    name: "Unnamed Base",
    type: FULLY_INTEGRATED_BASE,
    display_options: {
      canvas_size: {
        width: 1280,
        height: 720,
      },
      value_streams_visible: true,
      turfs_visible: true,
      forums_visible: true,
      horizontal_crews_visible: true,
      vertical_crews_visible: true,
      fit_container_x: true,
      fit_container_y: true,
      rectangular_base: false,
    },
  }

  const token = await auth.currentUser?.getIdToken();

  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/bases/`, {
    method: "POST",
    headers: {
      "Authorization": "" + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(unfixBase),
  });

  if (!response.ok) {
    throw new Error("Couldn't save user");
  }

  return response.json();
}

export async function deleteBase(baseId: string) {
  const token = await auth.currentUser?.getIdToken();

  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/bases/${baseId}/`, {
    method: "DELETE",
    headers: {
      "Authorization": "" + token,
    },
  });

  if (!response.ok) {
    throw new Error("Couldn't delete base");
  }
}

type BasePatchType = {
  name?: string;
  type?: number;
  lifecycle_stage?: number;
  revision_name?: string;
}

type EditBaseParamsType = {
  user: User | null;
  baseId: string;
  values: BasePatchType;
}

export async function patchBase(params: EditBaseParamsType) {
  const {user, baseId, values} = params;

  const token = await user?.getIdToken();

  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/bases/${baseId}/`, {
    method: "PATCH",
    headers: {
      "Authorization": "" + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  });

  if (!response.ok) {
    throw new Error("Couldn't update base");
  }

  return response.json();
}

type GetDisplayOptionsParamsType = {
  user: User | null;
  baseId: string;
};

export async function getDisplayOptions(params: GetDisplayOptionsParamsType) {
  const {user, baseId} = params;
  const token = await user?.getIdToken();

  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/display_options/?base=${baseId}`, {
    headers: {
      "Authorization": "" + token
    }
  });

  if (!response.ok) {
    throw new Error("Couldn't fetch display options");
  }

  return response.json();

}