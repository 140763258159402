import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import useValueStreamApi from "../../../apiHooks/useValueStreamApi";
import Spinner from "../../utils/Spinner";
import { VALUE_STREAM_TYPES } from "../../entities/ValueStream";
import { LIFECYCLE_STAGES } from "../../entities/Base";

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function ValueStreamDetails() {

  useIsAuthenticated();

  const navigate = useNavigate();

  const { baseId, valueStreamId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  const removeSuccessCB = () => {
    navigate(`/base/${baseId}`);
  }

  const { valueStreamQuery, editValueStream, removeValueStream } = useValueStreamApi({ baseId: baseId ?? "", user, valueStreamId, removeSuccessCB });

  return (
    // <div className="grid form-example">
    <div className="form-example" style={{ marginBottom: "3em" }}>
      <div style={{ marginBottom: "1em" }}>
        <Link className="link link-primary" to="/bases/">Home</Link> / <Link className="link link-primary" to={`/base/${baseId}`}>Base</Link> / {valueStreamQuery.isSuccess ? valueStreamQuery.data.name : "ValueStream"}
      </div>
      {/* <form className="cs2 ce9 form-example--main-content"> */}
      <div style={{marginTop: "1em", marginBottom: "1em"}}>
        {valueStreamQuery.isFetching && <Spinner text="Loading..." showText={true} />}
      </div>
      {!valueStreamQuery.isFetching && valueStreamQuery.data && <Formik
        enableReinitialize={true}
        initialValues={{ ...valueStreamQuery.data }}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          values.lifecycleStage = Number(values.lifecycleStage);
          editValueStream(values);
          actions.setSubmitting(false);
          // actions.resetForm();
        }}
      >
        <Form id="valueStreamEditForm" className="grid">
          <div className="cs1 ce10 form-group">
            <label htmlFor="name">Name</label>
            <Field name="name" placeholder="Value Stream name" className="input" />
            <ErrorMessage className="text-red-700" name="name" component="div" />
          </div>
          <div className="cs1 ce12 form-group">
            <label htmlFor="type">Type</label>
            <Field name="type" as="select" className="select">
              {VALUE_STREAM_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
            </Field>
            <ErrorMessage className="text-red-700" name="type" component="div" />
          </div>
          <div className="cs1 ce12 form-group">
            <label htmlFor="lifecycleStage">Lifecycle Stage</label>
            <Field name="lifecycleStage" as="select" className="select">
              <option value={0}>None</option>
              {LIFECYCLE_STAGES.map((stage) => <option key={stage.id} value={stage.id}>{stage.name}</option>)}
            </Field>
            <ErrorMessage className="text-red-700" name="lifecycleStage" component="div" />
          </div>
          <button type="submit" className="cs1 ce12 button button-primary">
            Save
          </button>
        </Form>

      </Formik>}
      <div className="grid" style={{ marginTop: "0.5rem" }}>
        <button type="button" onClick={() => { removeValueStream(valueStreamId ?? "") }} className="cs1 ce12 button button-danger">
          Delete ValueStream
        </button>
      </div>
    </div>
  );
}
