import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useCrewApi from "../../../apiHooks/useCrewApi";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CREW_TYPES } from "../../entities/Crew";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function AddCrew() {

  useIsAuthenticated();

  const { baseId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  const { addCrew } = useCrewApi({ baseId: baseId ?? "", user });

  const multiSelectStyle = {
    height: "110px",
    padding: "var(--space-xsmall) var(--space-xlarge) var(--space-xsmall) var(--space-small)",
    fontFamily: "var(--body-font)",
    fontSize: "var(--font-size-large)",
    width: "100%",
  }
  return (
    // <div className="grid form-example">
    <div className="form-example">
      <div style={{ marginBottom: "1em" }}>
      <Link className="link link-primary" to="/bases/">Home</Link> / <Link className="link link-primary" to={`/base/${baseId}`}>Base</Link> / New Crew
      </div>
      {/* <form className="cs2 ce9 form-example--main-content"> */}
      <Formik
        enableReinitialize={true}
        initialValues={{name: "", type: 1}}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          values.type = Number(values.type);
          addCrew(values);
          actions.setSubmitting(false);
          // actions.resetForm();
        }}
      >
        <Form id="crewEditForm" className="grid">
          <div className="cs1 ce11 form-group">
            <label htmlFor="name">Name</label>
            <Field name="name" placeholder="Crew name" className="input" />
            <ErrorMessage className="text-red-700" name="name" component="div" />
          </div>
          <div className="cs1 ce12 form-group">
            <label htmlFor="type">Type</label>
            <Field name="type" as="select" className="select">
              {CREW_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
            </Field>
            <ErrorMessage className="text-red-700" name="type" component="div" />
          </div>
          <button type="submit" className="cs1 ce12 button button-primary">
            Save
          </button>
        </Form>

      </Formik>
    </div>
  );
}
