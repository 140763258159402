import { Link, useParams } from "react-router-dom";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useBaseApi from "../../../apiHooks/useBaseApi";
import useCrewApi from "../../../apiHooks/useCrewApi";
import useForumApi from "../../../apiHooks/useForumApi";
import useTurfApi from "../../../apiHooks/useTurfApi";
import { DBCrewType, DBForumType, DBTurfType } from "../../../common/types";
import { crew_type_attributes } from "../../entities/Crew";
import { forum_type_attributes } from "../../entities/Forum";
import Spinner from "../../utils/Spinner";
import useValueStreamApi from "../../../apiHooks/useValueStreamApi";
import { turf_type_attributes } from "../../entities/Turf";
import { valueStream_type_attributes } from "../../entities/ValueStream";

export default function BaseDetails() {
  useIsAuthenticated();

  const { baseId } = useParams();
  const { user } = useContext(AuthContext) as AuthContextType;

  const { baseQuery } = useBaseApi({ user, baseId });
  const { crewsQuery } = useCrewApi({ user, baseId: baseId ?? "" });
  const { forumsQuery } = useForumApi({ user, baseId: baseId ?? "" });
  const { turfsQuery } = useTurfApi({ user, baseId: baseId ?? "" });
  const { valueStreamsQuery } = useValueStreamApi({ user, baseId: baseId ?? "" });

  const getCrewSytle = (type: number) => {
    return {
      backgroundColor: crew_type_attributes[type]["color"],
      padding: "0.25rem",
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
      borderRadius: "0.25rem",
      display: "flex",
      // flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    }
  }

  const getForumSytle = (type: number) => {
    return {
      backgroundColor: forum_type_attributes[type]["color"],
      padding: "0.25rem",
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
      borderRadius: "0.25rem",
      display: "flex",
      // flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    }
  }

  const getTurfSytle = (type: number) => {
    return {
      backgroundColor: '#d6d3d1',
      padding: "0.25rem",
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
      borderRadius: "0.25rem",
      borderColor: turf_type_attributes[type]["color"],
      display: "flex",
      // flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    }
  }

  const getValueStreamSytle = (type: number) => {
    return {
      backgroundColor: valueStream_type_attributes[type]["color"],
      padding: "0.25rem",
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
      borderRadius: "0.25rem",
      display: "flex",
      // flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      color: "white",
    }
  }

  return (
    <div style={{ marginBottom: "3em" }}>
      <div style={{ marginBottom: "1em" }}>
        <Link className="link link-primary" to="/bases/">Home</Link> / Base
      </div>
      <div className="grid">
        <div className="cs1 ce12">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h3>Value Streams</h3>
            <Link className="link link-primary" to={`/base/${baseId}/add_value_stream`}>Add Value Stream</Link>
          </div>
          {valueStreamsQuery.isFetching && <Spinner text="Loading..." showText={true} />}
          {valueStreamsQuery.isSuccess && !!valueStreamsQuery.data.length && valueStreamsQuery.data.map((valueStream: DBTurfType) => (
            <div key={`value_stream_${valueStream.id}`} style={getValueStreamSytle(valueStream.type)}>
              <span>{valueStream.name}</span>
              <Link to={`/base/${baseId}/value_stream/${valueStream.id}`} className="button button-tertiary button-small" type="button" aria-label="label">
                <span className="icon-eye"></span>
              </Link>
            </div>
          ))}
          {!(valueStreamsQuery.isSuccess && !!valueStreamsQuery.data.length) && !valueStreamsQuery.isFetching && <div>No items...</div>}
        </div>
        <div className="cs1 ce12">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h3>Crews</h3>
            <Link className="link link-primary" to={`/base/${baseId}/add_crew`}>Add Crew</Link>
          </div>
          {crewsQuery.isFetching && <Spinner text="Loading..." showText={true} />}
          {crewsQuery.isSuccess && !!crewsQuery.data.length && crewsQuery.data.map((crew: DBCrewType) => (
            <div key={`crew_${crew.id}`} style={getCrewSytle(crew.type)}>
              <span>{crew.name}</span>
              <Link to={`/base/${baseId}/crew/${crew.id}`} className="button button-tertiary button-small" type="button" aria-label="label">
                <span className="icon-eye"></span>
              </Link>
            </div>
          ))}
          {!(crewsQuery.isSuccess && !!crewsQuery.data.length) && !crewsQuery.isFetching && <div>No items...</div>}
        </div>
        <div className="cs1 ce12">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h3>Forums</h3>
            <Link className="link link-primary" to={`/base/${baseId}/add_forum`}>Add Forum</Link>
          </div>
          {forumsQuery.isFetching && <Spinner text="Loading..." showText={true} />}
          {forumsQuery.isSuccess && !!forumsQuery.data.length && forumsQuery.data.map((forum: DBForumType) => (
            <div key={`forum_${forum.id}`} style={getForumSytle(forum.type)}>
              <span>{forum.name}</span>
              <Link to={`/base/${baseId}/forum/${forum.id}`} className="button button-tertiary button-small" type="button" aria-label="label">
                <span className="icon-eye"></span>
              </Link>
            </div>
          ))}
          {!(forumsQuery.isSuccess && !!forumsQuery.data.length) && !forumsQuery.isFetching && <div>No items...</div>}
        </div>
        <div className="cs1 ce12">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h3>Turfs</h3>
            <Link className="link link-primary" to={`/base/${baseId}/add_turf`}>Add Turf</Link>
          </div>
          {turfsQuery.isFetching && <Spinner text="Loading..." showText={true} />}
          {turfsQuery.isSuccess && !!turfsQuery.data.length && turfsQuery.data.map((turf: DBTurfType) => (
            <div key={`turf_${turf.id}`} style={getTurfSytle(turf.type)}>
              <span>{turf.name}</span>
              <Link to={`/base/${baseId}/turf/${turf.id}`} className="button button-tertiary button-small" type="button" aria-label="label">
                <span className="icon-eye"></span>
              </Link>
            </div>
          ))}
          {!(turfsQuery.isSuccess && !!turfsQuery.data.length) && !turfsQuery.isFetching && <div>No items...</div>}
        </div>
      </div>
    </div>
  )
}
