import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useBaseApi from "../../../apiHooks/useBaseApi";
import useCrewApi from "../../../apiHooks/useCrewApi";
import { DBCrewType, FetchedBaseType } from "../../../common/types";
import { drawBase, drawCrew } from "../lib/draw";
import SyncedCrews from "./SyncedCrews";
import { SyncedBaseIdType } from "./SyncLogic";

type BaseMiroData = {
  frameId: string,
  shapeId: string,
  iconId: string,
}

export type BaseAppData = {
  base: FetchedBaseType;
  appData: BaseMiroData;
}

type SyncedBaseProps = {
  // baseaId: string;
  syncedBase: SyncedBaseIdType;
}

export default function SyncedBase({ syncedBase }: SyncedBaseProps) {
  const { user } = useContext(AuthContext) as AuthContextType;
  const { baseQuery } = useBaseApi({ user, baseId: syncedBase.id });

  const syncBase = async (base: FetchedBaseType) => {
    let baseAppData: BaseAppData = await window.miro.board.getAppData(`unfix_base_${syncedBase.id}`);

    let baseMiroData: BaseMiroData | undefined = undefined;

    // if (base.display_options?.canvas_size.width && base.display_options?.canvas_size.height) {
    //   x = (base.display_options.canvas_size.width / 2);
    //   y = (base.display_options.canvas_size.height / 2);
    // }

    if (!baseAppData) {

      await window.miro.board.viewport.set({
        viewport: {
          x: syncedBase.left - 100,
          y: syncedBase.top - 100,
          width: 1480,
          height: 920,
        }
      });

      baseMiroData = await drawBase(syncedBase.x, syncedBase.y, base);

    } else {
      try {
        const shape = await window.miro.board.getById(baseAppData.appData.shapeId);
      } catch (e) {
        console.log("Base was deleted from the board");
        baseMiroData = await drawBase(syncedBase.x, syncedBase.y, base);
      }
    }

    if (!(baseMiroData?.frameId && baseMiroData?.iconId && baseMiroData?.shapeId)) {
      // if (!(baseMiroData?.frameId && baseMiroData?.shapeId)) {
      return;
    }

    const newBaseAppData: BaseAppData = {
      base: {
        ...base,
        display_options: !base.display_options ? undefined : {
          ...base.display_options,
          canvas_size: {
            ...base.display_options.canvas_size
          }
        }
      },
      appData: {
        ...baseMiroData
      }
    }

    await window.miro.board.setAppData(`unfix_base_${syncedBase.id}`, newBaseAppData);
  }


  useEffect(() => {
    console.log("syncedbase useeffect", syncedBase.id);
    if (baseQuery.isSuccess && !baseQuery.isFetching && baseQuery.data) {
      syncBase(baseQuery.data);

      console.log("baseQuery");
    }
  }, [
    baseQuery.data,
    baseQuery.isSuccess,
    baseQuery.isFetching,
  ]);

  return (null);
}
