import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { DBForumPatchType, DBForumType, FormForumType } from '../common/types';
import { User } from 'firebase/auth';
import { getForumDefaultMeasurements } from "../components/canvas/lib/measurements";
import { createForum, deleteForum, getForum, getForums, patchForum } from '../api/forumApi';

type useForumApiParams = {
  baseId: string;
  user: User | null;
  addSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  patchSuccessCB?: () => void;
  forumId?: string;
}

export default function useForumApi({
  baseId,
  user,
  addSuccessCB,
  removeSuccessCB,
  patchSuccessCB,
  forumId
}: useForumApiParams) {
  const queryClient = useQueryClient();
  const forumQuery = useQuery(["forum", baseId, forumId],
    // @ts-ignore
    () => getForum({ user, baseId, forumId }),
    { enabled: !!user && !!baseId && !!forumId });
  const forumsQuery = useQuery(["forums", baseId], () => getForums({ user, baseId }), { enabled: !!user && !!baseId });
  const createForumMutation = useMutation(createForum);
  const deleteForumMutation = useMutation(deleteForum);
  const patchForumMutation = useMutation(patchForum);

  const addForum = (formData: FormForumType) => {
    let forum: DBForumType = {
      ...formData,
      id: "",
      base: baseId,
      measurements: getForumDefaultMeasurements(
        forumsQuery.data?.length,
        forumsQuery.data?.length + 1,
        1280,
        720,
      ),
    };

    createForumMutation.mutate({ user, baseId, forum }, {
      onSuccess: (data) => {
        forum["id"] = data.id;
        let newList = [...forumsQuery.data, forum];
        queryClient.setQueryData(["forums", baseId], newList);
        // queryClient.invalidateQueries(["forums", baseId]);

        if (addSuccessCB) {
          addSuccessCB();
        }
      },
    });
  }

  const removeForum = (forumId: string) => {
    // if (window.confirm("Are you sure you want to delete this Forum?") === true) {
      deleteForumMutation.mutate({ user, baseId, forumId }, {
        onSuccess: () => {
          const newList = forumsQuery.data.filter((element: DBForumType) => element.id !== forumId);
          queryClient.setQueryData(["forums", baseId], newList);
          // queryClient.invalidateQueries(["forums", baseId]);

          if (removeSuccessCB) {
            removeSuccessCB();
          }
        }
      });
    // }
  }

  const editForum = (forum: DBForumPatchType) => {
    if (!forumId) {
      return;
    }

    const toSave = {
      ...forum,
      id: forumId,
    };
    patchForumMutation.mutate({user, baseId, forum: toSave}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["forum", baseId, forumId]);
        queryClient.invalidateQueries(["forums", baseId]);

        if (patchSuccessCB) {
          patchSuccessCB();
        }
      }
    })
  }

  return {
    forumQuery,
    forumsQuery,
    createForumMutation,
    addForum,
    deleteForumMutation,
    removeForum,
    patchForumMutation,
    editForum,
  };
}
