import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

type SignInType = {
  email: string;
  password: string;
}

export default function SignIn() {
  const navigate = useNavigate();
  const { user, googleLogin, emailSignIn } = useContext(AuthContext) as AuthContextType;
  let [searchParams, setSearchParams] = useSearchParams();

  const handleLogin = async () => {
    try {
      googleLogin();
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (user) {
      let redirect_to = searchParams.get("redirect_to");
      if (!redirect_to) {
        redirect_to = "/";
      }
      navigate(redirect_to);
    }
  }, [user, navigate]);


  const initialValues: SignInType = {
    email: "",
    password: "",
  };

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });


  return (
    <div style={{ marginBottom: "3em" }}>
      <div style={{ marginBottom: "1em" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link className="link link-primary" to="/help">Learn more</Link>
          <span style={{marginLeft: "0.5em"}} className="icon icon-help-question"></span>
        </div>
      </div>
      <div className="grid">
        <div className="cs1 ce11">
          {/* <img src="https://images.squarespace-cdn.com/content/v1/61af660e0ccda12fd7774c2a/6ef11888-a6fd-45e4-ab83-a61d3a4f9f00/logo-symbol-white.png?format=300w" /> */}
          <h1>Sign in</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={(values, actions) => {
              emailSignIn(values.email, values.password).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
              });
              actions.setSubmitting(false);
            }}
          >
            <Form className="cs2 ce8" style={{ gridAutoRows: "1fr auto" }}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field type="email" name="email" placeholder="email@example.com" className="input" />
                <ErrorMessage style={{ color: "var(--red700)" }} name="email" component="div" />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field type="password" name="password" className="input" />
                <ErrorMessage style={{ color: "var(--red700)" }} name="password" component="div" />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <button
                  style={{ margin: "0" }}
                  type="submit"
                  className="button button-primary"
                >
                  Sign in
                </button>
                <span style={{ padding: "1em" }}>or</span>
                <button role="button" onClick={handleLogin} className="button button-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" /><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" /><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" /><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" /><path d="M1 1h22v22H1z" fill="none" /></svg>
                  Sign in with Google
                </button>
              </div>
            </Form>
          </Formik>
          <div>
            {/* <div>
              <h3>Sign in with</h3>
              <button role="button" onClick={handleLogin} className="button button-primary">Sign in with Google</button>
            </div> */}
            <div style={{ marginTop: "1em" }}>
              <div style={{ marginBottom: "0.5em" }}>
                <a className="link link-primary" target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_IMAGE_DOMAIN}/forgotten_password`}>Forgotten your password?</a>
              </div>
              <div>
                <span>Don't have an account? </span>
                <a className="link link-primary" target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_IMAGE_DOMAIN}/sign_up`}>Sign up</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}