import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import useTurfApi from "../../../apiHooks/useTurfApi";
import Spinner from "../../utils/Spinner";
import { TURF_TYPES } from "../../entities/Turf";

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function TurfDetails() {

  useIsAuthenticated();

  const navigate = useNavigate();

  const { baseId, turfId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  const removeSuccessCB = () => {
    navigate(`/base/${baseId}`);
  }

  const { turfQuery, editTurf, removeTurf } = useTurfApi({ baseId: baseId ?? "", user, turfId, removeSuccessCB });

  return (
    // <div className="grid form-example">
    <div className="form-example" style={{ marginBottom: "3em" }}>
      <div style={{ marginBottom: "1em" }}>
        <Link className="link link-primary" to="/bases/">Home</Link> / <Link className="link link-primary" to={`/base/${baseId}`}>Base</Link> / {turfQuery.isSuccess ? turfQuery.data.name : "Turf"}
      </div>
      {/* <form className="cs2 ce9 form-example--main-content"> */}
      <div style={{marginTop: "1em", marginBottom: "1em"}}>
        {turfQuery.isFetching && <Spinner text="Loading..." showText={true} />}
      </div>
      {!turfQuery.isFetching && turfQuery.data && <Formik
        enableReinitialize={true}
        initialValues={{ ...turfQuery.data }}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          editTurf(values);
          actions.setSubmitting(false);
          // actions.resetForm();
        }}
      >
        <Form id="turfEditForm" className="grid">
          <div className="cs1 ce10 form-group">
            <label htmlFor="name">Name</label>
            <Field name="name" placeholder="Turf name" className="input" />
            <ErrorMessage className="text-red-700" name="name" component="div" />
          </div>
          <div className="cs1 ce12 form-group">
            <label htmlFor="type">Type</label>
            <Field name="type" as="select" className="select">
              {TURF_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
            </Field>
            <ErrorMessage className="text-red-700" name="type" component="div" />
          </div>
          <button type="submit" className="cs1 ce12 button button-primary">
            Save
          </button>
        </Form>

      </Formik>}
      <div className="grid" style={{ marginTop: "0.5rem" }}>
        <button type="button" onClick={() => { removeTurf(turfId ?? "") }} className="cs1 ce12 button button-danger">
          Delete Turf
        </button>
      </div>
    </div>
  );
}
