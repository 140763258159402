import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import SyncedBase from "./SyncedBase";
import SyncedCrews from "./SyncedCrews";
import { Item, ItemsDeleteEvent, ItemsUpdateEvent, SelectionUpdateEvent } from "@mirohq/websdk-types";
import SyncedForums from "./SyncedForums";
import SyncedTurfs from "./SyncedTurfs";

import { Fragment } from "react";
import SyncedValueStreams from "./SyncedValueStreams";

export type SyncedBaseIdType = {
  id: string;
  name: string;
  x: number;
  y: number;
  left: number;
  top: number;
}

export type SelectedEntityType = {
  shapeId: string;
  type: "crew"|"forum"|"turf";
  id: string;
  baseId: string;
}

type SyncLogicProps = {
  baseIds: SyncedBaseIdType[],
  setBaseIds: (baseIds: SyncedBaseIdType[]) => void;
}

export default function SyncLogic({ baseIds, setBaseIds }: SyncLogicProps) {
  const { user } = useContext(AuthContext) as AuthContextType;

  useEffect(() => {
    const getSyncedBaseIds = async () => {
      const fetchedBaseIds = await window.miro.board.getAppData("unfix_base_ids");

      console.log("fetchedBaseIds", fetchedBaseIds);

      if (fetchedBaseIds) {
        // @ts-ignore
        setBaseIds(fetchedBaseIds);
      }
    }

    if (window.miro.board && user) {
      // boardInfo();
      getSyncedBaseIds();
    }
  }, [window.miro.board, user]);

  const handleSelect = useCallback(async (event: SelectionUpdateEvent) => {
    // await window.miro.board.setAppData("selected_entity", {});
    // setSelectedEntity(null);
    if (event.items.length === 1) {
      const item: Item = event.items[0];
      if (item.type === "shape") {
        // @ts-ignore
        const metaData: ShapeMetaDataType = await item.getMetadata("unfix");

        if (metaData) {
          await window.miro.board.select({ id: [...metaData.toSelect] });
        }
        if (metaData && metaData.baseId) {
          const entityData: SelectedEntityType = {
            type: metaData.entity,
            id: metaData.entityId,
            shapeId: item.id,
            baseId: metaData.baseId,
          };
          await window.miro.board.setAppData("selected_entity", entityData)
          await window.miro.board.ui.openPanel({ url: `/base/${metaData.baseId}/${metaData.entity}/${metaData.entityId}` });
        }
      }

      // else if (item.type === "frame" && baseId) {
      //   let baseAppData: BaseAppData = await window.miro.board.getAppData("unfix_base");
      //   if (baseAppData?.appData?.frameId && item.id === baseAppData.appData.frameId) {
      //     await window.miro.board.ui.openPanel({ url: `/base/${baseId}` });
      //   }
      // }
    }
  }, [])

  // const handleElementUpdate = useCallback(async (event: ItemsUpdateEvent) => {
  //   console.log("update", event);
  // }, []);

  const handleElementDelete = useCallback(async (event: ItemsDeleteEvent) => {
    if (event.items) {
      for (let item of event.items) {
        if (item.type === "shape") {
          console.log("it is a shape", item);
          let selectedEntity: SelectedEntityType = await window.miro.board.getAppData("selected_entity");
          console.log(selectedEntity);

          // @ts-ignore
          if (selectedEntity && item.id === selectedEntity.shapeId) {
            console.log("it was the selected shape");
            await window.miro.board.ui.closePanel();
            await window.miro.board.ui.openModal({url: `/delete/${selectedEntity.baseId}/${selectedEntity.type}/${selectedEntity.id}`, width: 300, height: 150});
          }
        }
      }
    }
    console.log("deleted evet", event);
  }, [])

  useEffect(() => {
    // if (baseId) {
    // console.log("baseId when setting", baseId);
    window.miro.board.ui.on("selection:update", handleSelect);
    window.miro.board.ui.on("items:delete", handleElementDelete);
    // }

    // window.miro.board.setAppData("unfix_base_ids", []);

    return () => {
      // if (baseId) {
      window.miro.board.ui.off("selection:update", handleSelect);
      window.miro.board.ui.off("items:delete", handleElementDelete);
      // }
    }
  }, [])


  return (
    <>
      {
        baseIds.map((syncedBaseId) => <Fragment key={`f_${syncedBaseId.id}`}>
          {<SyncedBase key={`sb_${syncedBaseId.id}`} syncedBase={syncedBaseId} />}
          {<SyncedTurfs key={`st_${syncedBaseId.id}`} syncedBase={syncedBaseId} />}
          {<SyncedForums key={`sf_${syncedBaseId.id}`} syncedBase={syncedBaseId} />}
          {<SyncedCrews key={`sc_${syncedBaseId.id}`} syncedBase={syncedBaseId} />}
          {<SyncedValueStreams key={`svs_${syncedBaseId.id}`} syncedBase={syncedBaseId} />}
        </Fragment>)
      }


    </>
  );
}
