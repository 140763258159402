import { User } from "firebase/auth";
import { DBValueStreamPatchType, DBValueStreamType } from "../common/types";

type GetValueStreamsParamsType = {
  user: User | null;
  baseId: string;
}

export async function getValueStreams(params: GetValueStreamsParamsType) {
  const {user, baseId} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/value_streams/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch valueStream list");
  }

  return response.json();
}

type GetValueStreamParamsType = {
  user: User | null;
  baseId: string;
  valueStreamId: string;
};

export async function getValueStream(params: GetValueStreamParamsType) {
  const { user, baseId, valueStreamId } = params;
  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/value_streams/${valueStreamId}/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token
      }
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch valueStream");
  }

  return response.json();
}

type CreateValueStreamParamsType = {
  user: User | null;
  baseId: string | undefined;
  valueStream: DBValueStreamType;
};

export async function createValueStream(params: CreateValueStreamParamsType) {
  const { user, baseId, valueStream } = params;

  const flattenedValueStream = {
    name: valueStream.name,
    type: valueStream.type,
    base: valueStream.base,
    // lifecycleStage: valueStream.lifecycleStage ?? 0,
    measurements: valueStream.measurements,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/value_streams/?base=${baseId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedValueStream }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create valueStream");
  }

  return response.json();
}

type PatchValueStreamParamsType = {
  user: User | null;
  baseId: string | undefined;
  valueStream: DBValueStreamPatchType;
};

export async function patchValueStream(params: PatchValueStreamParamsType) {
  const { user, baseId, valueStream } = params;

  const flattenedValueStream = {
    name: valueStream.name,
    type: valueStream.type,
    base: baseId,
    lifecycleStage: valueStream.lifecycleStage ?? 0,
    measurements: valueStream.measurements,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/value_streams/${valueStream.id}/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedValueStream }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch valueStream");
  }

  return response.json();
}

type DeleteValueStreamParamsType = {
  user: User | null;
  baseId: string | undefined;
  valueStreamId: string;
};

export async function deleteValueStream(params: DeleteValueStreamParamsType) {
  const { user, baseId, valueStreamId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/value_streams/${valueStreamId}/?base=${baseId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete valueStream");
  }
}
