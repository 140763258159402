import { useParams } from "react-router-dom"
import useCrewApi from "../../../apiHooks/useCrewApi";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";

export default function DeleteCrewConfirm() {
  const { baseId, crewId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  const closeModal = async () => {
    await window.miro.board.ui.closeModal();
  }

  const {removeCrew} = useCrewApi({user, baseId: baseId ?? "", crewId, removeSuccessCB: () => {closeModal()}});

  return (
    <div className="grid">
      <div className="cs1 ce12">
        <p>Would you like to delete this Crew from the database?</p>
        <div style={{display: "flex", justifyContent: "end", alignItems: "start"}}>
          <button onClick={closeModal} className="button button-secondary">No</button>
          {crewId && <button onClick={() => {removeCrew(crewId)}} className="button button-danger">Yes, delete</button>}
        </div>
      </div>
    </div>
  )
}
